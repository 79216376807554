import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import WarningIcon from '@mui/icons-material/Warning';

const Modal_SMStwilio = ({ open, onClose, services }) => {
  const getStatusIcon = (status) => {
    switch (status) {
      case 'operational':
        return <CheckCircleIcon style={{ color: 'green' }} />;
      case 'degraded_performance':
        return <WarningIcon style={{ color: 'orange' }} />;
      default:
        return <ErrorIcon style={{ color: 'red' }} />;
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Dettagli Status SMS Twilio</DialogTitle>
      <DialogContent>
        <List>
          {services.map((service, index) => (
            <ListItem key={index}>
              <ListItemIcon>
                {getStatusIcon(service.status)}
              </ListItemIcon>
              <ListItemText 
                primary={service.name}
                secondary={`Status: ${service.status}`}
              />
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Chiudi
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Modal_SMStwilio;