import React, { useState, useRef, useEffect } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, TextField, CircularProgress, Typography, Box } from '@mui/material';
import { update_user_phone, sendOTPDoubleAuth, verifyOTPDoubleAuth } from './Util_API_calls';

const Modal_ChangePhone = ({ isOpen, onClose, customer_id, currentPhone }) => {
  const [newPhone, setNewPhone] = useState('');
  const [otp, setOtp] = useState(Array(6).fill(''));
  const [isSendingOTP, setIsSendingOTP] = useState(false);
  const [isVerifyingOTP, setIsVerifyingOTP] = useState(false);
  const [isUpdatingPhone, setIsUpdatingPhone] = useState(false);
  const [otpVerified, setOtpVerified] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const inputsRef = useRef([]);

  useEffect(() => {
    if (currentPhone) {
      setNewPhone(currentPhone);
    }
  }, [currentPhone]);

  const handleSendOTP = async () => {
    setIsSendingOTP(true);
    setErrorMessage('');
    setSuccessMessage('');
    try {
      const response = await sendOTPDoubleAuth('admin_universal_double_auth', 'sms');
      if (response.status === 'success') {
        setSuccessMessage('OTP sent successfully');
      } else {
        setErrorMessage('Error sending OTP');
      }
    } catch (error) {
      setErrorMessage('Error sending OTP');
    } finally {
      setIsSendingOTP(false);
    }
  };

  const handleVerifyOTP = async () => {
    setIsVerifyingOTP(true);
    setErrorMessage('');
    setSuccessMessage('');
  
    const otpCode = otp.join('');
    console.log('OTP being sent:', otpCode); // Log the OTP
  
    try {
      console.log('Calling verifyOTPDoubleAuth with:', otpCode);
      const response = await verifyOTPDoubleAuth(otpCode);
      console.log('API Response:', response); // Log the full response
  
      if (response.status === 'success') {
        setOtpVerified(true);
        setSuccessMessage('OTP verified successfully');
      } else {
        setErrorMessage(JSON.stringify(response.details) || 'Error verifying OTP');
      }
    } catch (error) {
      console.error('Error in verifyOTPDoubleAuth:', error);
      setErrorMessage('Error verifying OTP: ' + error.message);
    } finally {
      setIsVerifyingOTP(false);
    }
  };

  const handleChangePhone = async () => {
    setIsUpdatingPhone(true);
    setErrorMessage('');
    setSuccessMessage('');

    if (!isValidPhoneNumber(newPhone)) {
      setErrorMessage('Invalid phone number format');
      setIsUpdatingPhone(false);
      return;
    }

    try {
      const result = await update_user_phone(customer_id, newPhone);
      if (result.status === 'success') {
        setSuccessMessage(result.details);
      } else {
        setErrorMessage(result.message || 'Error updating phone number');
      }
    } catch (error) {
      setErrorMessage('Error updating phone number: ' + error.message);
    } finally {
      setIsUpdatingPhone(false);
    }
  };

  const isValidPhoneNumber = (phone) => {
    const phoneRegex = /^\+[1-9]\d{1,14}$/;
    return phoneRegex.test(phone);
  };

  const handleChange = (index, value) => {
    if (!/^[0-9]$/.test(value)) return;
    setOtp(prevOtp => {
      const newOtp = [...prevOtp];
      newOtp[index] = value;
      return newOtp;
    });
    setTimeout(() => {
      if (index < otp.length - 1) {
        inputsRef.current[index + 1] && inputsRef.current[index + 1].focus();
      }
    }, 10);
  };

  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace') {
      e.preventDefault();
      if (otp[index] === '') {
        const prevIndex = index - 1;
        if (prevIndex >= 0) {
          setOtp(prevOtp => {
            const newOtp = [...prevOtp];
            newOtp[prevIndex] = '';
            return newOtp;
          });
          setTimeout(() => {
            inputsRef.current[prevIndex].focus();
          }, 0);
        }
      } else {
        setOtp(prevOtp => {
          const newOtp = [...prevOtp];
          newOtp[index] = '';
          return newOtp;
        });
        setTimeout(() => {
          inputsRef.current[index].focus();
        }, 0);
      }
    }
  };

  const handleClose = () => {
    setNewPhone(currentPhone);
    setOtp(Array(6).fill(''));
    setOtpVerified(false);
    setErrorMessage('');
    setSuccessMessage('');
    onClose();
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>Update Phone Number</DialogTitle>
      <DialogContent>
        <Box mb={2}>
          <Typography variant="body1">Customer ID: {customer_id}</Typography>
          <Typography variant="body1">Current Phone: {currentPhone}</Typography>
        </Box>
        {errorMessage && (
          <Box color="error.main" mb={2}>
            <Typography>{errorMessage}</Typography>
          </Box>
        )}
        {successMessage && (
          <Box color="success.main" mb={2}>
            <Typography>{successMessage}</Typography>
          </Box>
        )}
        {!otpVerified && (
          <>
            <Box mb={2}>
              <Button onClick={handleSendOTP} variant="contained" color="primary" disabled={isSendingOTP}>
                {isSendingOTP ? <CircularProgress size={24} /> : 'Send OTP'}
              </Button>
            </Box>
            <Box display="flex" justifyContent="center">
              {otp.map((digit, index) => (
                <TextField
                  key={index}
                  variant="outlined"
                  inputProps={{
                    maxLength: 1,
                    style: { textAlign: 'center' }
                  }}
                  inputRef={el => inputsRef.current[index] = el}
                  value={digit}
                  onChange={(e) => handleChange(index, e.target.value)}
                  onKeyDown={(e) => handleKeyDown(e, index)}
                />
              ))}
            </Box>
            <Box mt={2}>
              <Button onClick={handleVerifyOTP} variant="contained" color="primary" disabled={isVerifyingOTP}>
                {isVerifyingOTP ? <CircularProgress size={24} /> : 'Verifica OTP'}
              </Button>
            </Box>
          </>
        )}
        {otpVerified && (
          <TextField
            label="New Phone Number"
            value={newPhone}
            onChange={(e) => setNewPhone(e.target.value)}
            fullWidth
            margin="normal"
            helperText="Enter phone number in international format (e.g., +1234567890)"
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        {otpVerified && (
          <Button onClick={handleChangePhone} variant="contained" color="primary" disabled={isUpdatingPhone}>
            {isUpdatingPhone ? <CircularProgress size={24} /> : 'Update Phone Number'}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default Modal_ChangePhone;