import React, { useState, useEffect, useRef } from 'react';
import { TextField, Typography, Button, Link, CircularProgress, ThemeProvider } from '@mui/material';
import { theme } from './ReactStyles';
import { useNavigate } from 'react-router-dom';
import { getCookieValue, setCookieValue } from './App';
import './css/Unauthenticated.css';
import config from './config/env.json';
import logopng from './pics/logo.png';
import { t } from './Util_format';
import { fetchSendLoginOTP, fetchVerifyLoginOTP } from './Util_API_calls';

function Login_otp() {
  const [otp, setOtp] = useState(Array(6).fill(""));
  const [timer, setTimer] = useState(30);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const inputsRef = useRef([]);
  const submitButtonRef = useRef(null);
  const [isProcessing, setIsProcessing] = useState(false);

  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace' && !e.target.value) {
      if (index > 0) {
        inputsRef.current[index - 1].focus();
      }
    }
    if (e.key === 'Enter') {
      handleSubmit(); // Call the login function when Enter key is pressed
    }
  };

  useEffect(() => {
    const cookieRow = document.cookie.split('; ').find(row => row.startsWith('firebaseToken='));
    const cookieValue = cookieRow ? cookieRow.split('=')[1] : null;
    const loginOtpCompleted = getCookieValue('login_otp_completed');
    const KycCompleted = getCookieValue('kyc_completed');

    if (!cookieValue) {
      navigate('/login');
    } else {
      if (KycCompleted === 'false') {
        navigate('/kyc');
      }
      if (loginOtpCompleted === 'true') {
        navigate("/dashboard");
      }
    }

    if (inputsRef.current[0]) {
      inputsRef.current[0].focus();
    }

  }, []);

  useEffect(() => {
    if (timer > 0) {
      const timeoutId = setTimeout(() => setTimer(timer - 1), 1000);
      return () => clearTimeout(timeoutId);
    }
  }, [timer]);

  const handleChange = (index, value) => {
    if (!/^[0-9]$/.test(value) && value !== "") return;

    setOtp(prevOtp => {
      const newOtp = [...prevOtp];
      newOtp[index] = value;
      return newOtp;
    });

    if (value) {
      if (index === 5) {
        submitButtonRef.current.focus();
      } else if (index < 5) {
        inputsRef.current[index + 1].focus();
      }
    }
  };

  const handleSubmit = async () => {
    setIsProcessing(true);
    setError(null); // Resetting any previous errors
    const otpCode = otp.join("");
  
    if (!/^\d{6}$/.test(otpCode)) {
      setError("Per favore inserisci un codice OTP di 6 cifre.");
      setIsProcessing(false);
      return;
    }
  
    try {
      const apiResult = await fetchVerifyLoginOTP(otpCode);
      if (apiResult.details === 'System under maintenance. Please try again later.') {
        navigate("/maintenance");
      }

      if (apiResult.status !== 'success') {
        // Check if there are specific error details
        if (apiResult.message) {
          switch (apiResult.message) {
            case 'The otp code field is required':
              setError("Il codice OTP è richiesto.");
              break;
            case 'OTP has expired':
              setError("Il codice OTP è scaduto. Per favore richiedi un nuovo codice.");
              break;
            case 'Invalid OTP':
              setError("Il codice OTP inserito non è valido.");
              break;
            case 'OTP Attempts Exceeded':
              setError("Tentativi OTP esauriti. Per favore fai un nuovo login.");
              navigate('/login?action=clean-cookie&reason=too_many_attempts');
              break;
            case 'Email was not verified':
              navigate('/login?action=clean-cookie&reason=email_not_verified');
              break;
            default:
              // If no specific error message, display the general error message
              setError(apiResult.message || "Si è verificato un errore.");
              break;
          }
        } else {
          // If no specific error details, use the general error message
          setError(apiResult.message || "Si è verificato un errore.");
        }
        setIsProcessing(false);
        return;
      }  
      setCookieValue('login_otp_completed', 'true');
      navigate("/dashboard");
    } catch (error) {
      setError("Si è verificato un errore di rete.");
      setIsProcessing(false);
    }
  };
  
  

  const handleResendOtp = async () => {
    setIsProcessing(true);
    setError(null); // Resetting any previous errors
    setOtp(Array(6).fill(""));

    const token = getCookieValue('firebaseToken');
 
    try {
      const apiResult = await fetchSendLoginOTP();
      setTimer(30);
    } catch (error) {
      console.error("Login error:", error);
      setError(error.message);
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <ThemeProvider theme={theme}>
    <div id="page_login_otp">
      <div className="container">
        <div className="unauthenticated_left_container">
          <img src={logopng} alt={t("logo")} className="login_logo_desktop" />
          <div className="overlayImage"></div>
        </div>

        <div className="unauthenticated_right_container">
          <div style={{ width: "100%" }}>
            <img src={logopng} alt={t("logo")} className="login_logo_mobile" />
          </div>
          <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
            {t("sms_verification_title")}
          </Typography>
          <Typography variant="body1" gutterBottom>
            {t("we_sent_you_code")}
          </Typography>

  <div className="otpInputs" style={{ display: 'flex', justifyContent: 'space-between' }}>
  {otp.map((digit, index) => (
    <TextField
      key={index}
      variant="outlined"
      inputProps={{
        maxLength: 1,
        style: { 
          textAlign: 'center',
          width: '40px',
          height: '40px',
          fontSize: '1.5rem',
          padding: '8px'
        }
      }}
      sx={{ width: '50px', margin: '0 4px' }}
      inputRef={ref => inputsRef.current[index] = ref}
      value={digit}
      onChange={(e) => handleChange(index, e.target.value)}
      onKeyDown={(e) => handleKeyDown(e, index)}
    />
  ))}
</div>

          {timer > 0 ? (
            <div>
              <div style={{ width: "100%", paddingTop: '10px'}}>{t("resend_sms_in")}</div>
              <div style={{ position: 'relative', width: '50px', height: '50px', width: '100%', marginTop: ' 20px', marginBottom: ' 20px' }}>

                <CircularProgress
                  variant="determinate"
                  value={(100 * timer) / 30}
                  size={50}
                />
                <div style={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  fontWeight: 'bold'
                }}>
                  {timer}
                </div>
              </div>
            </div>
          ) : (
            <Link
              id="resendSMSLink"
              href="#"
              variant="body2"
              underline="none"
              className="reSendSMS"
              onClick={handleResendOtp}
              disabled={isProcessing}
            >
              {t("resend_sms")}
            </Link>
          )}

          {error &&
            <div className="error-container">
              <div className="error-icon">!</div>
              <Typography className="error-text">{error}</Typography>
            </div>
          }

          <Button
            ref={submitButtonRef}
            variant="contained"
            color="primary"
            className={`unauthenticated_button ${isProcessing ? "processingButton" : ""}`}
            disabled={isProcessing}
            onClick={handleSubmit}
          >
            {isProcessing ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              t("submit")
            )}
          </Button>

          <Link href="/login?action=clean-cookie" variant="body2" underline="none" className="returnToLogin">
            {t("back_to_login")}
          </Link>
        </div>
      </div>
    </div>
    </ThemeProvider>
  );
}

export default Login_otp;