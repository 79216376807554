
import React, { useEffect, useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, CircularProgress, Typography, Table, TableBody, TableCell, TableHead, TableRow, TextField, TableSortLabel } from '@mui/material';
import { fetch_customer_transactions } from './Util_API_calls';

const Modal_CustomerTransactions = ({ isOpen, onClose, userEmail }) => {
  const [transactionsData, setTransactionsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [orderBy, setOrderBy] = useState('created_at');
  const [orderDirection, setOrderDirection] = useState('desc');

  useEffect(() => {
    const fetchDetails = async () => {
      setLoading(true);
      setErrorMessage('');
      try {
        const result = await fetch_customer_transactions(userEmail);
        if (result.status === 'success') {
          setTransactionsData(result.account_transactions);
        } else {
          setErrorMessage(result.message || 'Non ci sono dettagli al momento');
        }
      } catch (error) {
        setErrorMessage('Non ci sono dettagli al momento');
      }
      setLoading(false);
    };

    if (isOpen) {
      fetchDetails();
    }
  }, [isOpen, userEmail]);

  const handleClose = () => {
    setTransactionsData([]);
    setErrorMessage('');
    onClose();
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleRequestSort = (property) => {
    const isAscending = orderBy === property && orderDirection === 'asc';
    setOrderDirection(isAscending ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const filteredTransactions = transactionsData.filter(transaction =>
    Object.values(transaction).some(value =>
      value && value.toString().toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  const sortedTransactions = filteredTransactions.sort((a, b) => {
    if (a[orderBy] == null) return 1;
    if (b[orderBy] == null) return -1;

    if (orderBy === 'amount' || orderBy === 'transaction_id') {
      return orderDirection === 'asc' ? a[orderBy] - b[orderBy] : b[orderBy] - a[orderBy];
    }

    if (orderBy === 'created_at') {
      return orderDirection === 'asc' ? new Date(a[orderBy]) - new Date(b[orderBy]) : new Date(b[orderBy]) - new Date(a[orderBy]);
    }

    return orderDirection === 'asc'
      ? a[orderBy].toString().localeCompare(b[orderBy].toString())
      : b[orderBy].toString().localeCompare(a[orderBy].toString());
  });

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      maxWidth="lg"
      fullWidth
      PaperProps={{
        style: {
          resize: 'both',
          overflow: 'auto',
          width: '80%',
          maxHeight: '80vh'
        }
      }}
    >
      <DialogTitle>Transazioni Cliente</DialogTitle>
      <DialogContent>
        <TextField
          label="Cerca"
          value={searchTerm}
          onChange={handleSearchChange}
          fullWidth
          margin="normal"
        />
        {loading ? (
          <CircularProgress />
        ) : errorMessage ? (
          <Typography color="error">{errorMessage}</Typography>
        ) : sortedTransactions.length === 0 ? (
          <Typography>Non ci sono dettagli al momento</Typography>
        ) : (
          <Table>
            <TableHead>
              <TableRow>
                {['transaction_id', 'account_from', 'account_to', 'amount', 'currency', 'transaction_type', 'description', 'created_at'].map(column => (
                  <TableCell key={column} style={{ fontWeight: 'bold', fontSize: '1.1rem' }}>
                    <TableSortLabel
                      active={orderBy === column}
                      direction={orderBy === column ? orderDirection : 'asc'}
                      onClick={() => handleRequestSort(column)}
                    >
                      {column.replace('_', ' ')}
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedTransactions.map((transaction) => (
                <TableRow key={transaction.transaction_id}>
                  <TableCell>{transaction.transaction_id}</TableCell>
                  <TableCell>{transaction.account_from}</TableCell>
                  <TableCell>{transaction.account_to}</TableCell>
                  <TableCell>{transaction.amount}</TableCell>
                  <TableCell>{transaction.currency}</TableCell>
                  <TableCell>{transaction.transaction_type}</TableCell>
                  <TableCell>{transaction.description}</TableCell>
                  <TableCell>{new Date(transaction.created_at).toLocaleString()}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Chiudi
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Modal_CustomerTransactions;
