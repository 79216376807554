import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow, TablePagination, CircularProgress, Box, Typography } from '@mui/material';
import Page_FeeValues from './Page_FeeValues';
import { get_financial_data } from './Util_API_calls';

const Page_DailyOutputs = ({ onDataChange }) => {
  const [data, setData] = useState([]);
  const [feeValues, setFeeValues] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(true);
  const [aggregateBy, setAggregateBy] = useState('day');
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonthIndex = currentDate.getMonth();
  const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  const currentMonthName = months[currentMonthIndex];
  const [month, setMonth] = useState(currentMonthName);
  const [year, setYear] = useState(currentYear.toString());

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      let dateStart, dateEnd;
      if (aggregateBy === 'month') {
        dateStart = `${year}-01-01`;
        dateEnd = `${year}-12-31`;
      } else {
        const monthIndex = months.indexOf(month) + 1;
        const monthPadded = monthIndex.toString().padStart(2, '0');
        dateStart = `${year}-${monthPadded}-01`;

        const lastDayOfMonth = new Date(year, monthIndex, 0).getDate();
        dateEnd = `${year}-${monthPadded}-${lastDayOfMonth}`;
      }

      const response = await get_financial_data('volume', dateStart, dateEnd, aggregateBy, month, year);
      if (response && response.status === 'success') {
        setData(Object.values(response.data));
        onDataChange && onDataChange(response.data);
      }

      setLoading(false);
    };

    if (aggregateBy && year && (aggregateBy !== 'day' || (aggregateBy === 'day' && month))) {
      setPage(0);
      fetchData();
    }
  }, [aggregateBy, month, year, onDataChange]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleFeeValuesChange = (feeValues) => {
    setFeeValues(feeValues);
  };

  const calculateCardBalanceEnquiryFees = (dailyOutputs) => {
    return dailyOutputs.map(output => {
      const { date, login_quantity } = output;
      const feeValue = feeValues ? Page_FeeValues.getValidFeeValue('card_balance_enquiry_fee', date, feeValues) : null;

      if (!feeValue) {
        return { date, fee: null };
      }

      const { ul_fixed_component, crh_fixed_component, customer_fixed_component } = feeValue;
      const fee = (customer_fixed_component - (ul_fixed_component + crh_fixed_component)) * login_quantity;
      return { date, fee };
    });
  };

  const calculateCumulativeCardBalanceEnquiryFees = (fees) => {
    let cumulativeFee = 0;
    return fees.map(({ date, fee }) => {
      if (fee !== null) {
        cumulativeFee += fee;
      }
      return { date, cumulativeFee };
    });
  };

  const cardBalanceEnquiryFees = feeValues ? calculateCardBalanceEnquiryFees(data) : [];
  const cumulativeCardBalanceEnquiryFees = calculateCumulativeCardBalanceEnquiryFees(cardBalanceEnquiryFees);

  return (
    <Box>
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          <Page_FeeValues dailyTransactions={data} onDataChange={handleFeeValuesChange} />

          <Box mt={4} mb={2}>
            <Typography variant="h6">Daily Card Balance Enquiry Fees</Typography>
          </Box>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Date</TableCell>
                <TableCell>Card Balance Enquiry Fee</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {cardBalanceEnquiryFees.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(({ date, fee }) => (
                <TableRow key={date}>
                  <TableCell>{date}</TableCell>
                  <TableCell>{fee !== null ? fee : 'N/A'}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>

          <Box mt={4} mb={2}>
            <Typography variant="h6">Cumulative Card Balance Enquiry Fees</Typography>
          </Box>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Date</TableCell>
                <TableCell>Cumulative Card Balance Enquiry Fee</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {cumulativeCardBalanceEnquiryFees.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(({ date, cumulativeFee }) => (
                <TableRow key={date}>
                  <TableCell>{date}</TableCell>
                  <TableCell>{cumulativeFee}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>

          <TablePagination
            component="div"
            count={data.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
      )}
    </Box>
  );
};

export default Page_DailyOutputs;