import React, { useState, useEffect } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, CircularProgress, Typography } from '@mui/material';
import { fetch_customer_details } from './Util_API_calls'; // Corrected import

const Modal_CustomerDetail = ({ isOpen, onClose, customer_id }) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (isOpen) {
      fetchCustomerDetails();
    }
  }, [isOpen]);

  const fetchCustomerDetails = async () => {
    setLoading(true);
    try {
      const result = await fetch_customer_details(customer_id);
      if (result.status === 'success') {
        setData(result.user_data);
      } else {
        setErrorMessage(result.message || 'Non ci sono dettagli al momento');
      }
    } catch (error) {
      setErrorMessage('Non ci sono dettagli al momento');
    }
    setLoading(false);
  };

  const handleClose = () => {
    setErrorMessage('');
    setData(null);
    onClose();
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>Dettagli cliente</DialogTitle>
      <DialogContent>
        {loading ? (
          <CircularProgress />
        ) : errorMessage ? (
          <Typography color="error">{errorMessage}</Typography>
        ) : data ? (
          Object.keys(data).length === 0 ? (
            <Typography>Non ci sono dettagli al momento</Typography>
          ) : (
            <>
              <Typography variant="body1">Customer ID: {data.customer_id}</Typography>
              <Typography variant="body1">First Name: {data.first_name}</Typography>
              <Typography variant="body1">Last Name: {data.last_name}</Typography>
              <Typography variant="body1">Middle Name: {data.middle_name}</Typography>
              <Typography variant="body1">Email: {data.email}</Typography>
              <Typography variant="body1">City: {data.city}</Typography>
              <Typography variant="body1">Country: {data.country}</Typography>
              <Typography variant="body1">Phone: {data.phone}</Typography>
              <Typography variant="body1">Kyc: {data.kyc_status_id}</Typography>
              <Typography variant="body1">Address 1: {data.addr_line_1}</Typography>
              <Typography variant="body1">Address 2: {data.addr_line_2}</Typography>
              <Typography variant="body1">Created: {data.created_at}</Typography>
              <Typography variant="body1">Updated: {data.updated_at}</Typography>

              {/* Add more fields as needed */}
            </>
          )
        ) : (
          <Typography>Non ci sono dettagli al momento</Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">OK</Button>
      </DialogActions>
    </Dialog>
  );
};

export default Modal_CustomerDetail;
