import React, { useState, useEffect } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Typography, CircularProgress, Box } from '@mui/material';
import { fetch_customer_shipping_addresses } from './Util_API_calls';

const Modal_ShippingAddress = ({ isOpen, onClose, customer_id }) => {
  const [addresses, setAddresses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchAddresses = async () => {
      setLoading(true);
      setError(null);
      try {
        const result = await fetch_customer_shipping_addresses(customer_id);
        if (result.status === 'success') {
          setAddresses(result.addresses || []);
        } else {
          setError(result.message || 'An error occurred while fetching addresses.');
        }
      } catch (error) {
        setError('An error occurred while fetching addresses.');
      }
      setLoading(false);
    };

    if (isOpen) {
      fetchAddresses();
    }
  }, [isOpen, customer_id]);

  const renderAddressContent = () => {
    if (loading) {
      return <CircularProgress />;
    }

    if (error) {
      return <Typography color="error">{error}</Typography>;
    }

    if (addresses.length === 0) {
      return <Typography>Non ci sono dettagli al momento</Typography>;
    }

    return addresses.map((address, index) => (
      <Box key={index} sx={{ mb: 2, p: 2, border: '1px solid #ddd', borderRadius: 1 }}>
        <Typography><strong>Nome:</strong> {address.name} {address.surname}</Typography>
        <Typography><strong>Indirizzo:</strong> {address.addr_line_1} {address.addr_line2}</Typography>
        <Typography><strong>Città:</strong> {address.city}</Typography>
        <Typography><strong>Stato/Provincia:</strong> {address.state}</Typography>
        <Typography><strong>CAP:</strong> {address.zip_code}</Typography>
        <Typography><strong>Paese:</strong> {address.country}</Typography>
        <Typography><strong>Stato:</strong> {address.status}</Typography>
      </Box>
    ));
  };

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Indirizzo di Spedizione</DialogTitle>
      <DialogContent>
        {renderAddressContent()}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Chiudi
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Modal_ShippingAddress;