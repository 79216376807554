import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Grid, Typography, Box } from '@mui/material';
import Modal_UnableDisable from './Modal_UnableDisable';
import Modal_AMLWatchdog from './Modal_AMLWatchdog';
import Modal_ChangeEmail from './Modal_ChangeEmail';
import Modal_ChangePhone from './Modal_ChangePhone';
import Modal_ShippingAddress from './Modal_ShippingAddress';
import Modal_CustomerCards from './Modal_CustomerCards';
import Modal_CardTransactions from './Modal_CardTransactions';

const Modal_CustomerOptions = ({ isOpen, onClose, user, onOptionSelect, allEmails }) => {
  const [unableDisableModalOpen, setUnableDisableModalOpen] = useState(false);
  const [amlWatchdogModalOpen, setAmlWatchdogModalOpen] = useState(false);
  const [changeEmailModalOpen, setChangeEmailModalOpen] = useState(false);
  const [changePhoneModalOpen, setChangePhoneModalOpen] = useState(false);
  const [shippingAddressModalOpen, setShippingAddressModalOpen] = useState(false);
  const [customerCardsModalOpen, setCustomerCardsModalOpen] = useState(false);
  const [cardTransactionsModalOpen, setCardTransactionsModalOpen] = useState(false);
  const [selectedCardId, setSelectedCardId] = useState(null);

  const handleOptionClick = (option) => {
    if (option === 'enable') {
      setUnableDisableModalOpen(true);
    } else if (option === 'aml_watchdog') {
      setAmlWatchdogModalOpen(true);
    } else if (option === 'change_email') {
      setChangeEmailModalOpen(true);
    } else if (option === 'change_phone') {
      setChangePhoneModalOpen(true);
    } else if (option === 'shipping_address') {
      setShippingAddressModalOpen(true);
    } else if (option === 'cards') {
      setCustomerCardsModalOpen(true);
    } else {
      onOptionSelect(option);
    }
  };

  const handleUnableDisableModalClose = () => {
    setUnableDisableModalOpen(false);
  };

  const handleAmlWatchdogModalClose = () => {
    setAmlWatchdogModalOpen(false);
  };

  const handleChangeEmailModalClose = () => {
    setChangeEmailModalOpen(false);
  };

  const handleChangePhoneModalClose = () => {
    setChangePhoneModalOpen(false);
  };

  const handleShippingAddressModalClose = () => {
    setShippingAddressModalOpen(false);
  };

  const handleShowCardTransactions = (card_id) => {
    setSelectedCardId(card_id);
    setCardTransactionsModalOpen(true);
  };

  const options = [
    { label: 'Vedi dettagli cliente', value: 'details' },
    { label: 'Vedi conti cliente', value: 'accounts' },
    { label: 'Vedi transazioni cliente', value: 'transactions' },
    { label: 'Vedi carte cliente', value: 'cards' },
    { label: 'Abilita/Disabilita cliente', value: 'enable' },
    { label: 'Aggiorna telefono', value: 'change_phone' },
    { label: 'Aggiorna email', value: 'change_email' },
    { label: 'AML Watchdog', value: 'aml_watchdog' },
    { label: 'Indirizzo di spedizione', value: 'shipping_address' },
  ];

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={onClose}
        maxWidth="sm"
        fullWidth
        disableEscapeKeyDown
        onBackdropClick={(event) => {
          if (event.target === event.currentTarget) {
            onClose();
          }
        }}
      >
        <Box sx={{ bgcolor: '#2196f3', color: 'white', textAlign: 'center' }}>
          <DialogTitle>Customer Options</DialogTitle>
        </Box>
        <DialogContent>
          <Box sx={{ mb: 3, p: 2, bgcolor: 'background.paper', borderRadius: 1 }}>
            <Typography variant="h6" component="div" gutterBottom>
              Cliente: {user.first_name} {user.last_name}
            </Typography>
            <Typography variant="body1" gutterBottom>
              Email: {user.email}
            </Typography>
            <Typography variant="body1" gutterBottom>
              Customer ID: {user.customer_id}
            </Typography>
            <Typography variant="body1">
              KYC Status: {user.kyc_status_id}
            </Typography>
          </Box>
          <Grid container spacing={2}>
            {options.map((option) => (
              <Grid item xs={6} key={option.value}>
                <Button
                  fullWidth
                  variant="outlined"
                  onClick={() => handleOptionClick(option.value)}
                >
                  {option.label}
                </Button>
              </Grid>
            ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {unableDisableModalOpen && (
        <Modal_UnableDisable
          isOpen={unableDisableModalOpen}
          onClose={handleUnableDisableModalClose}
          customer_id={user.customer_id}
        />
      )}
      {amlWatchdogModalOpen && (
        <Modal_AMLWatchdog
          isOpen={amlWatchdogModalOpen}
          onClose={handleAmlWatchdogModalClose}
          customer_id={user.customer_id}
        />
      )}
      {changeEmailModalOpen && (
        <Modal_ChangeEmail
          isOpen={changeEmailModalOpen}
          onClose={handleChangeEmailModalClose}
          customer_id={user.customer_id}
          currentEmail={user.email}
          allEmails={allEmails}
        />
      )}
      {changePhoneModalOpen && (
        <Modal_ChangePhone
          isOpen={changePhoneModalOpen}
          onClose={handleChangePhoneModalClose}
          customer_id={user.customer_id}
          currentPhone={user.phone}
        />
      )}
      {shippingAddressModalOpen && (
        <Modal_ShippingAddress
          isOpen={shippingAddressModalOpen}
          onClose={handleShippingAddressModalClose}
          customer_id={user.customer_id}
        />
      )}
      {customerCardsModalOpen && (
        <Modal_CustomerCards
          isOpen={customerCardsModalOpen}
          onClose={() => setCustomerCardsModalOpen(false)}
          customer_id={user.customer_id}
          onShowCardTransactions={handleShowCardTransactions}
        />
      )}
      {cardTransactionsModalOpen && (
        <Modal_CardTransactions
          isOpen={cardTransactionsModalOpen}
          onClose={() => setCardTransactionsModalOpen(false)}
          customer_id={user.customer_id}
          card_id={selectedCardId}
        />
      )}
    </>
  );
};

export default Modal_CustomerOptions;