import React from 'react';
import { Modal, Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TableSortLabel } from '@mui/material';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  maxHeight: '80vh',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  overflow: 'auto',
};

const tableContainerStyle = {
  maxHeight: '60vh',
  overflowY: 'auto',
};

const Modal_TopCardSpenders = ({ open, handleClose, transactions }) => {
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('amount_spent');

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const sortedTransactions = React.useMemo(() => {
    const comparator = (a, b) => {
      if (!a || !b) return 0;

      if (orderBy === 'amount_spent') {
        return b.amount_spent - a.amount_spent;
      }
      if (orderBy === 'first_name') {
        return order === 'asc' 
          ? (a.first_name || '').localeCompare(b.first_name || '') 
          : (b.first_name || '').localeCompare(a.first_name || '');
      }
      if (orderBy === 'last_name') {
        return order === 'asc' 
          ? (a.last_name || '').localeCompare(b.last_name || '') 
          : (b.last_name || '').localeCompare(a.last_name || '');
      }
      return 0;
    };

    return [...transactions].sort(comparator);
  }, [transactions, order, orderBy]);

  return (
    <Modal open={open} onClose={handleClose} aria-labelledby="modal-title" aria-describedby="modal-description">
      <Box sx={style}>
        <Typography id="modal-title" variant="h6" component="h2">
          Top 50 Card Spenders
        </Typography>
        <TableContainer component={Paper} sx={tableContainerStyle} style={{ marginTop: '20px' }}>
          <Table size="small" aria-label="top card spenders">
            <TableHead>
              <TableRow>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === 'first_name'}
                    direction={orderBy === 'first_name' ? order : 'asc'}
                    onClick={(event) => handleRequestSort(event, 'first_name')}
                  >
                    First Name
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === 'last_name'}
                    direction={orderBy === 'last_name' ? order : 'asc'}
                    onClick={(event) => handleRequestSort(event, 'last_name')}
                  >
                    Last Name
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === 'amount_spent'}
                    direction={orderBy === 'amount_spent' ? order : 'asc'}
                    onClick={(event) => handleRequestSort(event, 'amount_spent')}
                  >
                    Amount Spent
                  </TableSortLabel>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedTransactions.map((spender, index) => (
                <TableRow key={index}>
                  <TableCell>{spender.first_name || 'N/A'}</TableCell>
                  <TableCell>{spender.last_name || 'N/A'}</TableCell>
                  <TableCell>{new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'EUR' }).format(spender.amount_spent)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Modal>
  );
};

export default Modal_TopCardSpenders;