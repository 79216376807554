import React, { useEffect, useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, CircularProgress, Typography, Table, TableBody, TableCell, TableHead, TableRow, TextField, TableSortLabel } from '@mui/material';
import { fetch_aml_watchdog_events } from './Util_API_calls';

const Modal_AMLWatchdog = ({ isOpen, onClose, customer_id }) => {
  const [eventsData, setEventsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [eventCodeFilter, setEventCodeFilter] = useState('');
  const [orderBy, setOrderBy] = useState('created_at');
  const [orderDirection, setOrderDirection] = useState('desc');

  useEffect(() => {
    const fetchDetails = async () => {
      setLoading(true);
      setErrorMessage('');
      try {
        const result = await fetch_aml_watchdog_events(customer_id);
        if (result.status === 'success') {
          setEventsData(result.watchdog_events);
        } else {
          setErrorMessage(result.message || 'Non ci sono dettagli al momento');
        }
      } catch (error) {
        setErrorMessage('Non ci sono dettagli al momento');
      }
      setLoading(false);
    };

    if (isOpen) {
      fetchDetails();
    }
  }, [isOpen, customer_id]);

  const handleClose = () => {
    setEventsData([]);
    setErrorMessage('');
    onClose();
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleEventCodeFilterChange = (event) => {
    setEventCodeFilter(event.target.value);
  };

  const handleRequestSort = (property) => {
    const isAscending = orderBy === property && orderDirection === 'asc';
    setOrderDirection(isAscending ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const filteredEvents = eventsData.filter(event =>
    (eventCodeFilter === '' || event.event_code.toLowerCase().includes(eventCodeFilter.toLowerCase())) &&
    Object.values(event).some(value =>
      value && value.toString().toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  const sortedEvents = filteredEvents.sort((a, b) => {
    if (a[orderBy] == null) return 1;
    if (b[orderBy] == null) return -1;

    if (orderBy === 'created_at') {
      return orderDirection === 'asc' ? new Date(a[orderBy]) - new Date(b[orderBy]) : new Date(b[orderBy]) - new Date(a[orderBy]);
    }

    return orderDirection === 'asc'
      ? a[orderBy].toString().localeCompare(b[orderBy].toString())
      : b[orderBy].toString().localeCompare(a[orderBy].toString());
  });

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      maxWidth="lg"
      fullWidth
      PaperProps={{
        style: {
          resize: 'both',
          overflow: 'auto',
          width: '80%',
          maxHeight: '80vh'
        }
      }}
    >
      <DialogTitle>AML Watchdog Events</DialogTitle>
      <DialogContent>
        <TextField
          label="Cerca"
          value={searchTerm}
          onChange={handleSearchChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Filtra per Event Code"
          value={eventCodeFilter}
          onChange={handleEventCodeFilterChange}
          fullWidth
          margin="normal"
        />
        {loading ? (
          <CircularProgress />
        ) : errorMessage ? (
          <Typography color="error">{errorMessage}</Typography>
        ) : sortedEvents.length === 0 ? (
          <Typography>Non ci sono dettagli al momento</Typography>
        ) : (
          <Table>
            <TableHead>
              <TableRow>
                {['event_code', 'event_description', 'details', 'created_at'].map(column => (
                  <TableCell key={column} style={{ fontWeight: 'bold', fontSize: '1.1rem' }}>
                    <TableSortLabel
                      active={orderBy === column}
                      direction={orderBy === column ? orderDirection : 'asc'}
                      onClick={() => handleRequestSort(column)}
                    >
                      {column.replace('_', ' ')}
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedEvents.map((event) => (
                <TableRow key={event.event_code + event.created_at}>
                  <TableCell>{event.event_code}</TableCell>
                  <TableCell>{event.event_description}</TableCell>
                  <TableCell>{event.details}</TableCell>
                  <TableCell>{new Date(event.created_at).toLocaleString()}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Chiudi
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Modal_AMLWatchdog;