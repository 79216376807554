import React, { useEffect, useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, CircularProgress, Typography } from '@mui/material';
import { fetch_kyc_status } from './Util_API_calls'; // Update the import according to your project structure

const Modal_KYCStatus = ({ isOpen, onClose, userEmail }) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const fetchData = async () => {
    setLoading(true);
    try {
      const result = await fetch_kyc_status(userEmail);
      if (result.status === 'success') {
        setData(result.kyc_status);
      } else {
        setErrorMessage(result.message || 'Non ci sono dettagli al momento');
      }
    } catch (error) {
      setErrorMessage('Non ci sono dettagli al momento');
    }
    setLoading(false);
  };

  useEffect(() => {
    if (isOpen) {
      fetchData();
    }
  }, [isOpen]);

  const handleClose = () => {
    setErrorMessage('');
    setData(null);
    onClose();
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>KYC Status</DialogTitle>
      <DialogContent>
        {loading ? (
          <CircularProgress />
        ) : errorMessage ? (
          <Typography color="error">{errorMessage}</Typography>
        ) : data ? (
          Object.keys(data).length === 0 ? (
            <Typography>Non ci sono dettagli al momento</Typography>
          ) : (
            <>
              <Typography>KYC Status: {data.status}</Typography>
              <Typography>Updated At: {data.updated_at}</Typography>
              {/* Add more fields as needed */}
            </>
          )
        ) : (
          <Typography>Non ci sono dettagli al momento</Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">OK</Button>
      </DialogActions>
    </Dialog>
  );
};

export default Modal_KYCStatus;
