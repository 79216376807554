import React, { useState } from 'react';
import { Button, TextField, Typography, Box, CircularProgress, Paper} from '@mui/material';
import { refundCustomer, compareEpayTransactions, materialiseAdminFinancials, disconnectAllUsers, removeEpayFidoBlocker, materialiseBankAccountBalances, cleanupUnconfirmedEpayTransactions, sendOTPDoubleAuth, verifyOTPDoubleAuth, removeEpayLowFidoBlocker, showCachedTokenUnlimit, forceUpdateBaasToken } from './Util_API_calls';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

function Page_SuperAdminOperations() {

  // First tile states
  const [epayOtpCode, setEpayOtpCode] = useState('');
  const [epayOtpSent, setEpayOtpSent] = useState(false);
  const [epayOtpVerified, setEpayOtpVerified] = useState(false);
  const [epayOperationResult, setEpayOperationResult] = useState('');
  const [isEpayLoading, setIsEpayLoading] = useState(false);

  // Second tile states
  const [unlimitOtpSent, setUnlimitOtpSent] = useState(false);
  const [unlimitOtpCode, setUnlimitOtpCode] = useState('');
  const [unlimitOtpVerified, setUnlimitOtpVerified] = useState(false);
  const [unlimitOperationResult, setUnlimitOperationResult] = useState('');
  const [unlimitToken, setUnlimitToken] = useState('');
  const [isUnlimitLoading, setIsUnlimitLoading] = useState(false);

  // Third tile states
  const [baasTokenOtpSent, setBaasTokenOtpSent] = useState(false);
  const [baasTokenOtpCode, setBaasTokenOtpCode] = useState('');
  const [baasTokenOtpVerified, setBaasTokenOtpVerified] = useState(false);
  const [baasTokenOperationResult, setBaasTokenOperationResult] = useState('');
  const [isBaasTokenLoading, setIsBaasTokenLoading] = useState(false);


   // 4th tile states
  const [cleanupEpayOtpSent, setCleanupEpayOtpSent] = useState(false);
  const [cleanupEpayOtpCode, setCleanupEpayOtpCode] = useState('');
  const [cleanupEpayOtpVerified, setCleanupEpayOtpVerified] = useState(false);
  const [cleanupEpayOperationResult, setCleanupEpayOperationResult] = useState('');
  const [isCleanupEpayLoading, setIsCleanupEpayLoading] = useState(false);

   // 5th tile states
  const [bankAccountsOtpSent, setBankAccountsOtpSent] = useState(false);
  const [bankAccountsOtpCode, setBankAccountsOtpCode] = useState('');
  const [bankAccountsOtpVerified, setBankAccountsOtpVerified] = useState(false);
  const [bankAccountsOperationResult, setBankAccountsOperationResult] = useState('');
  const [isBankAccountsLoading, setIsBankAccountsLoading] = useState(false);
  const [updatedAccountsCount, setUpdatedAccountsCount] = useState(null);


  // 6th tile states
  const [epayFidoOtpSent, setEpayFidoOtpSent] = useState(false);
  const [epayFidoOtpCode, setEpayFidoOtpCode] = useState('');
  const [epayFidoOtpVerified, setEpayFidoOtpVerified] = useState(false);
  const [epayFidoOperationResult, setEpayFidoOperationResult] = useState('');
  const [isEpayFidoLoading, setIsEpayFidoLoading] = useState(false);
  const [epayFidoDetails, setEpayFidoDetails] = useState('');
 
  // 7th tile states
  const [adminFinancialsOtpSent, setAdminFinancialsOtpSent] = useState(false);
  const [adminFinancialsOtpCode, setAdminFinancialsOtpCode] = useState('');
  const [adminFinancialsOtpVerified, setAdminFinancialsOtpVerified] = useState(false);
  const [adminFinancialsOperationResult, setAdminFinancialsOperationResult] = useState('');
  const [isAdminFinancialsLoading, setIsAdminFinancialsLoading] = useState(false);
  const [dateStart, setDateStart] = useState('');
  const [dateEnd, setDateEnd] = useState('');
  const [financialData, setFinancialData] = useState('');




 // Eighth tile states
 const [epayCompareOtpSent, setEpayCompareOtpSent] = useState(false);
const [epayCompareOtpCode, setEpayCompareOtpCode] = useState('');
const [epayCompareOtpVerified, setEpayCompareOtpVerified] = useState(false);
const [epayCompareOperationResult, setEpayCompareOperationResult] = useState('');
const [isEpayCompareLoading, setIsEpayCompareLoading] = useState(false);
const [epayCompareContent, setEpayCompareContent] = useState('');
const [epayCompareData, setEpayCompareData] = useState('');


  // 9th tile states
const [refundOtpCode, setRefundOtpCode] = useState('');
const [refundOtpSent, setRefundOtpSent] = useState(false);
const [refundOtpVerified, setRefundOtpVerified] = useState(false);
const [refundOperationResult, setRefundOperationResult] = useState('');
const [isRefundLoading, setIsRefundLoading] = useState(false);
const [customerId, setCustomerId] = useState('');
const [refundAmount, setRefundAmount] = useState('');
const [refundCurrency, setRefundCurrency] = useState('EUR');
const [refundDescription, setRefundDescription] = useState('');


  // 10th tile states
  const [disconnectAllOtpSent, setDisconnectAllOtpSent] = useState(false);
  const [disconnectAllOtpCode, setDisconnectAllOtpCode] = useState('');
  const [disconnectAllOtpVerified, setDisconnectAllOtpVerified] = useState(false);
  const [disconnectAllOperationResult, setDisconnectAllOperationResult] = useState('');
  const [isDisconnectAllLoading, setIsDisconnectAllLoading] = useState(false);

  // First tile handlers
  const handleEpaySendOTP = async () => {
    try {
      setIsEpayLoading(true);
      const result = await sendOTPDoubleAuth('admin_universal_double_auth', 'sms');
      if (result.status === 'success') {
        setEpayOtpSent(true);
        setEpayOperationResult('OTP inviato con successo');
      } else {
        setEpayOperationResult('Errore nell\'invio dell\'OTP');
      }
    } catch (error) {
      setEpayOperationResult('Errore nell\'invio dell\'OTP');
    } finally {
      setIsEpayLoading(false);
    }
  };

  const handleEpayVerifyOTP = async () => {
    try {
      setIsEpayLoading(true);
      const result = await verifyOTPDoubleAuth(epayOtpCode);
      if (result.status === 'success') {
        setEpayOtpVerified(true);
        setEpayOperationResult('OTP verificato con successo');
      } else {
        setEpayOperationResult('Codice OTP non valido');
      }
    } catch (error) {
      setEpayOperationResult('Errore nella verifica dell\'OTP');
    } finally {
      setIsEpayLoading(false);
    }
  };

  const handleRemoveBlocker = async () => {
    try {
      setIsEpayLoading(true);
      const result = await removeEpayLowFidoBlocker();
      if (result.status === 'success') {
        setEpayOperationResult('Operazione di rimozione eseguita');
      } else {
        setEpayOperationResult('Errore nella rimozione del blocker');
      }
    } catch (error) {
      setEpayOperationResult('Errore nella rimozione del blocker');
    } finally {
      setIsEpayLoading(false);
    }
  };

  // Second tile handlers
  const handleUnlimitSendOTP = async () => {
    try {
      setIsUnlimitLoading(true);
      const result = await sendOTPDoubleAuth('admin_universal_double_auth', 'sms');
      if (result.status === 'success') {
        setUnlimitOtpSent(true);
        setUnlimitOperationResult('OTP inviato con successo');
      } else {
        setUnlimitOperationResult('Errore nell\'invio dell\'OTP');
      }
    } catch (error) {
      setUnlimitOperationResult('Errore nell\'invio dell\'OTP');
    } finally {
      setIsUnlimitLoading(false);
    }
  };

  const handleUnlimitVerifyOTP = async () => {
    try {
      setIsUnlimitLoading(true);
      const result = await verifyOTPDoubleAuth(unlimitOtpCode);
      if (result.status === 'success') {
        setUnlimitOtpVerified(true);
        setUnlimitOperationResult('OTP verificato con successo');
      } else {
        setUnlimitOperationResult('Codice OTP non valido');
      }
    } catch (error) {
      setUnlimitOperationResult('Errore nella verifica dell\'OTP');
    } finally {
      setIsUnlimitLoading(false);
    }
  };

  const handleShowCachedToken = async () => {
    try {
      setIsUnlimitLoading(true);
      const result = await showCachedTokenUnlimit();
      if (result.status === 'success') {
        setUnlimitToken(result.token);
        setUnlimitOperationResult('Token recuperato con successo');
      } else {
        setUnlimitOperationResult('Errore nel recupero del token');
      }
    } catch (error) {
      setUnlimitOperationResult('Errore nel recupero del token');
    } finally {
      setIsUnlimitLoading(false);
    }
  };

  const handleCopyToken = () => {
    navigator.clipboard.writeText(unlimitToken)
      .then(() => setUnlimitOperationResult('Token copiato negli appunti'))
      .catch(() => setUnlimitOperationResult('Errore nella copia del token'));
  };

  // Third tile handlers
  const handleBaasTokenSendOTP = async () => {
    try {
      setIsBaasTokenLoading(true);
      const result = await sendOTPDoubleAuth('admin_universal_double_auth', 'sms');
      if (result.status === 'success') {
        setBaasTokenOtpSent(true);
        setBaasTokenOperationResult('OTP inviato con successo');
      } else {
        setBaasTokenOperationResult('Errore nell\'invio dell\'OTP');
      }
    } catch (error) {
      setBaasTokenOperationResult('Errore nell\'invio dell\'OTP');
    } finally {
      setIsBaasTokenLoading(false);
    }
  };

  const handleBaasTokenVerifyOTP = async () => {
    try {
      setIsBaasTokenLoading(true);
      const result = await verifyOTPDoubleAuth(baasTokenOtpCode);
      if (result.status === 'success') {
        setBaasTokenOtpVerified(true);
        setBaasTokenOperationResult('OTP verificato con successo');
      } else {
        setBaasTokenOperationResult('Codice OTP non valido');
      }
    } catch (error) {
      setBaasTokenOperationResult('Errore nella verifica dell\'OTP');
    } finally {
      setIsBaasTokenLoading(false);
    }
  };

  const handleForceUpdateBaasToken = async () => {
    try {
      setIsBaasTokenLoading(true);
      const result = await forceUpdateBaasToken();
      if (result.status === 'success') {
        setBaasTokenOperationResult('Bass Token Aggiornata');
      } else {
        setBaasTokenOperationResult('Errore nell\'aggiornamento del Bass Token');
      }
    } catch (error) {
      setBaasTokenOperationResult('Errore nell\'aggiornamento del Bass Token');
    } finally {
      setIsBaasTokenLoading(false);
    }
  };

// 4 tile handlers

const handleCleanupEpaySendOTP = async () => {
  try {
    setIsCleanupEpayLoading(true);
    const result = await sendOTPDoubleAuth('admin_universal_double_auth', 'sms');
    if (result.status === 'success') {
      setCleanupEpayOtpSent(true);
      setCleanupEpayOperationResult('OTP inviato con successo');
    } else {
      setCleanupEpayOperationResult('Errore nell\'invio dell\'OTP');
    }
  } catch (error) {
    setCleanupEpayOperationResult('Errore nell\'invio dell\'OTP');
  } finally {
    setIsCleanupEpayLoading(false);
  }
};

const handleCleanupEpayVerifyOTP = async () => {
  try {
    setIsCleanupEpayLoading(true);
    const result = await verifyOTPDoubleAuth(cleanupEpayOtpCode);
    if (result.status === 'success') {
      setCleanupEpayOtpVerified(true);
      setCleanupEpayOperationResult('OTP verificato con successo');
    } else {
      setCleanupEpayOperationResult('Codice OTP non valido');
    }
  } catch (error) {
    setCleanupEpayOperationResult('Errore nella verifica dell\'OTP');
  } finally {
    setIsCleanupEpayLoading(false);
  }
};

const handleCleanupEpayTransactions = async () => {
  try {
    setIsCleanupEpayLoading(true);
    const result = await cleanupUnconfirmedEpayTransactions();
    if (result.status === 'success') {
      setCleanupEpayOperationResult('Transizioni Epay non confermate piu vecchie di 1 settimana cancellate');
    } else {
      setCleanupEpayOperationResult('Errore durante la pulizia delle transazioni Epay');
    }
  } catch (error) {
    setCleanupEpayOperationResult('Errore durante la pulizia delle transazioni Epay');
  } finally {
    setIsCleanupEpayLoading(false);
  }
};

// 5 tile handlers

const handleBankAccountsSendOTP = async () => {
  try {
    setIsBankAccountsLoading(true);
    const result = await sendOTPDoubleAuth('admin_universal_double_auth', 'sms');
    if (result.status === 'success') {
      setBankAccountsOtpSent(true);
      setBankAccountsOperationResult('OTP inviato con successo');
    } else {
      setBankAccountsOperationResult('Errore nell\'invio dell\'OTP');
    }
  } catch (error) {
    setBankAccountsOperationResult('Errore nell\'invio dell\'OTP');
  } finally {
    setIsBankAccountsLoading(false);
  }
};

const handleBankAccountsVerifyOTP = async () => {
  try {
    setIsBankAccountsLoading(true);
    const result = await verifyOTPDoubleAuth(bankAccountsOtpCode);
    if (result.status === 'success') {
      setBankAccountsOtpVerified(true);
      setBankAccountsOperationResult('OTP verificato con successo');
    } else {
      setBankAccountsOperationResult('Codice OTP non valido');
    }
  } catch (error) {
    setBankAccountsOperationResult('Errore nella verifica dell\'OTP');
  } finally {
    setIsBankAccountsLoading(false);
  }
};

const handleMaterialiseBankAccountBalances = async () => {
  try {
    setIsBankAccountsLoading(true);
    const result = await materialiseBankAccountBalances();
    if (result.status === 'success') {
      setBankAccountsOperationResult('Conti bancari aggiornati');
      setUpdatedAccountsCount(result.total_accounts_updated);
    } else {
      setBankAccountsOperationResult('Errore durante l\'aggiornamento dei conti bancari');
    }
  } catch (error) {
    setBankAccountsOperationResult('Errore durante l\'aggiornamento dei conti bancari');
  } finally {
    setIsBankAccountsLoading(false);
  }
};


// 6 tile handlers

const handleEpayFidoSendOTP = async () => {
  try {
    setIsEpayFidoLoading(true);
    const result = await sendOTPDoubleAuth('admin_universal_double_auth', 'sms');
    if (result.status === 'success') {
      setEpayFidoOtpSent(true);
      setEpayFidoOperationResult('OTP inviato con successo');
    } else {
      setEpayFidoOperationResult('Errore nell\'invio dell\'OTP');
    }
  } catch (error) {
    setEpayFidoOperationResult('Errore nell\'invio dell\'OTP');
  } finally {
    setIsEpayFidoLoading(false);
  }
};

const handleEpayFidoVerifyOTP = async () => {
  try {
    setIsEpayFidoLoading(true);
    const result = await verifyOTPDoubleAuth(epayFidoOtpCode);
    if (result.status === 'success') {
      setEpayFidoOtpVerified(true);
      setEpayFidoOperationResult('OTP verificato con successo');
    } else {
      setEpayFidoOperationResult('Codice OTP non valido');
    }
  } catch (error) {
    setEpayFidoOperationResult('Errore nella verifica dell\'OTP');
  } finally {
    setIsEpayFidoLoading(false);
  }
};

const handleRemoveEpayFidoBlocker = async () => {
  try {
    setIsEpayFidoLoading(true);
    const result = await removeEpayFidoBlocker();
    if (result.status === 'success') {
      setEpayFidoOperationResult('Epay Fido blocker rimosso');
      setEpayFidoDetails(result.details);
    } else {
      setEpayFidoOperationResult('Errore durante la rimozione del Epay Fido blocker');
    }
  } catch (error) {
    setEpayFidoOperationResult('Errore durante la rimozione del Epay Fido blocker');
  } finally {
    setIsEpayFidoLoading(false);
  }
};

// 7 tile handlers

const handleAdminFinancialsSendOTP = async () => {
  try {
    setIsAdminFinancialsLoading(true);
    const result = await sendOTPDoubleAuth('admin_universal_double_auth', 'sms');
    if (result.status === 'success') {
      setAdminFinancialsOtpSent(true);
      setAdminFinancialsOperationResult('OTP inviato con successo');
    } else {
      setAdminFinancialsOperationResult('Errore nell\'invio dell\'OTP');
    }
  } catch (error) {
    setAdminFinancialsOperationResult('Errore nell\'invio dell\'OTP');
  } finally {
    setIsAdminFinancialsLoading(false);
  }
};

const handleAdminFinancialsVerifyOTP = async () => {
  try {
    setIsAdminFinancialsLoading(true);
    const result = await verifyOTPDoubleAuth(adminFinancialsOtpCode);
    if (result.status === 'success') {
      setAdminFinancialsOtpVerified(true);
      setAdminFinancialsOperationResult('OTP verificato con successo');
    } else {
      setAdminFinancialsOperationResult('Codice OTP non valido');
    }
  } catch (error) {
    setAdminFinancialsOperationResult('Errore nella verifica dell\'OTP');
  } finally {
    setIsAdminFinancialsLoading(false);
  }
};

const handleMaterialiseAdminFinancials = async () => {
  try {
    setIsAdminFinancialsLoading(true);
    const result = await materialiseAdminFinancials(dateStart, dateEnd);
    if (result.status === 'success') {
      setAdminFinancialsOperationResult('Ecco i dati finanziari');
      setFinancialData(JSON.stringify(result, null, 2));
    } else {
      setAdminFinancialsOperationResult('Errore nel recupero dei dati finanziari');
    }
  } catch (error) {
    setAdminFinancialsOperationResult('Errore nel recupero dei dati finanziari');
  } finally {
    setIsAdminFinancialsLoading(false);
  }
};

const handleCopyFinancialData = () => {
  navigator.clipboard.writeText(financialData)
    .then(() => setAdminFinancialsOperationResult('Dati finanziari copiati negli appunti'))
    .catch(() => setAdminFinancialsOperationResult('Errore nella copia dei dati finanziari'));
};

// 8 tile handlers


const handleEpayCompareSendOTP = async () => {
  try {
    setIsEpayCompareLoading(true);
    const result = await sendOTPDoubleAuth('admin_universal_double_auth', 'sms');
    if (result.status === 'success') {
      setEpayCompareOtpSent(true);
      setEpayCompareOperationResult('OTP inviato con successo');
    } else {
      setEpayCompareOperationResult('Errore nell\'invio dell\'OTP');
    }
  } catch (error) {
    setEpayCompareOperationResult('Errore nell\'invio dell\'OTP');
  } finally {
    setIsEpayCompareLoading(false);
  }
};

const handleEpayCompareVerifyOTP = async () => {
  try {
    setIsEpayCompareLoading(true);
    const result = await verifyOTPDoubleAuth(epayCompareOtpCode);
    if (result.status === 'success') {
      setEpayCompareOtpVerified(true);
      setEpayCompareOperationResult('OTP verificato con successo');
    } else {
      setEpayCompareOperationResult('Codice OTP non valido');
    }
  } catch (error) {
    setEpayCompareOperationResult('Errore nella verifica dell\'OTP');
  } finally {
    setIsEpayCompareLoading(false);
  }
};

const handleCompareEpayTransactions = async () => {
  if (!epayCompareContent.trim()) {
    setEpayCompareOperationResult('Inserisci il contenuto del file CSV prima di procedere');
    return;
  }
  try {
    setIsEpayCompareLoading(true);
    console.log('Sending CSV content:', epayCompareContent.substring(0, 200) + '...'); // Log first 200 characters
    const result = await compareEpayTransactions(epayCompareContent);
    console.log('API Response:', result);
    if (result.status === 'success') {
      setEpayCompareOperationResult('Successo, ecco i dati finanziari');
      setEpayCompareData(JSON.stringify(result, null, 2));
    } else {
      setEpayCompareOperationResult('Errore nel confronto delle transazioni Epay: ' + result.message);
    }
  } catch (error) {
    setEpayCompareOperationResult('Errore nel confronto delle transazioni Epay: ' + error.message);
  } finally {
    setIsEpayCompareLoading(false);
  }
};

const handleEpayCompareContentChange = (event) => {
  setEpayCompareContent(event.target.value);
};

const handleCopyEpayCompareData = () => {
  navigator.clipboard.writeText(epayCompareData)
    .then(() => setEpayCompareOperationResult('Dati copiati negli appunti'))
    .catch(() => setEpayCompareOperationResult('Errore nella copia dei dati'));
};

// 9th tile handlers
const handleRefundSendOTP = async () => {
  try {
    setIsRefundLoading(true);
    const result = await sendOTPDoubleAuth('admin_universal_double_auth', 'sms');
    if (result.status === 'success') {
      setRefundOtpSent(true);
      setRefundOperationResult('OTP inviato con successo');
    } else {
      setRefundOperationResult('Errore nell\'invio dell\'OTP');
    }
  } catch (error) {
    setRefundOperationResult('Errore nell\'invio dell\'OTP');
  } finally {
    setIsRefundLoading(false);
  }
};

const handleRefundVerifyOTP = async () => {
  try {
    setIsRefundLoading(true);
    const result = await verifyOTPDoubleAuth(refundOtpCode);
    if (result.status === 'success') {
      setRefundOtpVerified(true);
      setRefundOperationResult('OTP verificato con successo');
    } else {
      setRefundOperationResult('Codice OTP non valido');
    }
  } catch (error) {
    setRefundOperationResult('Errore nella verifica dell\'OTP');
  } finally {
    setIsRefundLoading(false);
  }
};

const handleRefundCustomer = async () => {
  if (!customerId || !refundAmount || !refundCurrency || !refundDescription) {
    setRefundOperationResult('Compila tutti i campi prima di procedere');
    return;
  }
  try {
    setIsRefundLoading(true);
    const result = await refundCustomer({
      customer_id: customerId,
      amount: parseFloat(refundAmount).toFixed(2),
      currency: refundCurrency,
      description: refundDescription
    });
    if (result.status === 'success') {
      setRefundOperationResult('Rimborso effettuato con successo');
      // Reset form fields
      setCustomerId('');
      setRefundAmount('');
      setRefundDescription('');
    } else {
      setRefundOperationResult('Errore durante il rimborso: ' + result.message);
    }
  } catch (error) {
    setRefundOperationResult('Errore durante il rimborso: ' + error.message);
  } finally {
    setIsRefundLoading(false);
  }
};


// 10 tile handlers

const handleDisconnectAllSendOTP = async () => {
  try {
    setIsDisconnectAllLoading(true);
    const result = await sendOTPDoubleAuth('admin_universal_double_auth', 'sms');
    if (result.status === 'success') {
      setDisconnectAllOtpSent(true);
      setDisconnectAllOperationResult('OTP inviato con successo');
    } else {
      setDisconnectAllOperationResult('Errore nell\'invio dell\'OTP');
    }
  } catch (error) {
    setDisconnectAllOperationResult('Errore nell\'invio dell\'OTP');
  } finally {
    setIsDisconnectAllLoading(false);
  }
};

const handleDisconnectAllVerifyOTP = async () => {
  try {
    setIsDisconnectAllLoading(true);
    const result = await verifyOTPDoubleAuth(disconnectAllOtpCode);
    if (result.status === 'success') {
      setDisconnectAllOtpVerified(true);
      setDisconnectAllOperationResult('OTP verificato con successo');
    } else {
      setDisconnectAllOperationResult('Codice OTP non valido');
    }
  } catch (error) {
    setDisconnectAllOperationResult('Errore nella verifica dell\'OTP');
  } finally {
    setIsDisconnectAllLoading(false);
  }
};

const handleDisconnectAllUsers = async () => {
  try {
    setIsDisconnectAllLoading(true);
    const result = await disconnectAllUsers();
    if (result.status === 'success') {
      setDisconnectAllOperationResult('Utenti disconnessi, esci e fai il login per usare I servizi');
    } else {
      setDisconnectAllOperationResult('Errore durante la disconnessione degli utenti');
    }
  } catch (error) {
    setDisconnectAllOperationResult('Errore durante la disconnessione degli utenti');
  } finally {
    setIsDisconnectAllLoading(false);
  }
};


  return (
    <Box sx={{ padding: 3 }}>
      <Typography variant="h3" gutterBottom sx={{  color: '#339AD8', textAlign: 'center',marginBottom: '4rem' }}>
        Operazioni Super Admin
      </Typography>

      {/* First Tile */}
      <Box sx={{ 
        border: '1px solid #ccc', 
        borderRadius: 2, 
        padding: 6, 
        marginBottom: 2, 
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center', 
        textAlign: 'center' 
      }}>
        <Typography variant="h4" gutterBottom sx={{ color: 'black',marginBottom: '3rem' }}>
          Rimuovi Epay low fido blocker
        </Typography>
        {!epayOtpSent ? (
          <Button variant="contained" onClick={handleEpaySendOTP} disabled={isEpayLoading}>
            {isEpayLoading ? <CircularProgress size={24} /> : 'Manda codice OTP per rimuovere Epay'}
          </Button>
        ) : !epayOtpVerified ? (
          <>
            <TextField
              value={epayOtpCode}
              onChange={(e) => setEpayOtpCode(e.target.value.replace(/[^0-9]/g, '').slice(0, 6))}
              placeholder="Inserisci OTP"
              inputProps={{
                maxLength: 6,
                style: { 
                  textAlign: 'center', 
                  letterSpacing: '0.5em',
                  font: 'monospace',
                  fontSize: '1.5em'
                }
              }}
              sx={{ my: 2, width: '200px' }}
            />
            <Button variant="contained" onClick={handleEpayVerifyOTP} disabled={epayOtpCode.length !== 6 || isEpayLoading}>
              {isEpayLoading ? <CircularProgress size={24} /> : 'Verifica OTP code'}
            </Button>
          </>
        ) : (
          <Button variant="contained" onClick={handleRemoveBlocker} disabled={isEpayLoading}sx={{ backgroundColor: 'red', '&:hover': { backgroundColor: 'darkred' } }}>
            {isEpayLoading ? <CircularProgress size={24} /> : 'Rimuovi Epay low fido blocker'}
          </Button>
        )}
        {epayOperationResult && (
          <Typography sx={{ marginTop: 2, color: 'black' }}>
            {epayOperationResult}
          </Typography>
        )}
      </Box>

      {/* Second Tile */}
      <Box sx={{ 
        border: '1px solid #ccc', 
        borderRadius: 2, 
        padding: 6, 
        marginBottom: 2, 
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center', 
        textAlign: 'center' 
      }}>
        <Typography variant="h4" gutterBottom sx={{ color: 'black',marginBottom: '3rem' }}>
          Mostra Cached Token Unlimit
        </Typography>
        {!unlimitOtpSent ? (
          <Button variant="contained" onClick={handleUnlimitSendOTP} disabled={isUnlimitLoading}>
            {isUnlimitLoading ? <CircularProgress size={24} /> : 'Manda codice OTP'}
          </Button>
        ) : !unlimitOtpVerified ? (
          <>
            <TextField
              value={unlimitOtpCode}
              onChange={(e) => setUnlimitOtpCode(e.target.value.replace(/[^0-9]/g, '').slice(0, 6))}
              placeholder="Inserisci OTP"
              inputProps={{
                maxLength: 6,
                style: { 
                  textAlign: 'center', 
                  letterSpacing: '0.5em',
                  font: 'monospace',
                  fontSize: '1.5em'
                }
              }}
              sx={{ my: 2, width: '200px' }}
            />
            <Button variant="contained" onClick={handleUnlimitVerifyOTP} disabled={unlimitOtpCode.length !== 6 || isUnlimitLoading}>
              {isUnlimitLoading ? <CircularProgress size={24} /> : 'Verifica OTP code'}
            </Button>
          </>
        ) : (
          <>
            <Button variant="contained" onClick={handleShowCachedToken} disabled={isUnlimitLoading}sx={{ backgroundColor: 'red', '&:hover': { backgroundColor: 'darkred' } }}>
              {isUnlimitLoading ? <CircularProgress size={24} /> : 'Mostra Cached Token Unlimit'}
            </Button>
            {unlimitToken && (
              <Box sx={{ mt: 2, width: '100%' }}>
                <TextField
                  value={unlimitToken}
                  multiline
                  rows={4}
                  fullWidth
                  InputProps={{
                    readOnly: true,
                    endAdornment: (
                      <Button onClick={handleCopyToken}>
                        <ContentCopyIcon />
                      </Button>
                    ),
                  }}
                />
              </Box>
            )}
          </>
        )}
        {unlimitOperationResult && (
          <Typography sx={{ marginTop: 2, color: 'black' }}>
            {unlimitOperationResult}
          </Typography>
        )}
      </Box>

      {/* Third Tile */}
      <Box sx={{ 
        border: '1px solid #ccc', 
        borderRadius: 2, 
        padding: 6, 
        marginBottom: 2, 
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center', 
        textAlign: 'center' 
      }}>
        <Typography variant="h4" gutterBottom sx={{ color: 'black',marginBottom: '3rem' }}>
        Forza Aggiornamento Token Unlimit
        </Typography>
        {!baasTokenOtpSent ? (
          <Button variant="contained" onClick={handleBaasTokenSendOTP} disabled={isBaasTokenLoading}>
            {isBaasTokenLoading ? <CircularProgress size={24} /> : 'Manda codice OTP'}
          </Button>
        ) : !baasTokenOtpVerified ? (
          <>
            <TextField
              value={baasTokenOtpCode}
              onChange={(e) => setBaasTokenOtpCode(e.target.value.replace(/[^0-9]/g, '').slice(0, 6))}
              placeholder="Inserisci OTP"
              inputProps={{
                maxLength: 6,
                style: { 
                  textAlign: 'center', 
                  letterSpacing: '0.5em',
                  font: 'monospace',
                  fontSize: '1.5em'
                }
              }}
              sx={{ my: 2, width: '200px' }}
            />
            <Button variant="contained" onClick={handleBaasTokenVerifyOTP} disabled={baasTokenOtpCode.length !== 6 || isBaasTokenLoading}>
              {isBaasTokenLoading ? <CircularProgress size={24} /> : 'Verifica OTP code'}
            </Button>
          </>
        ) : (
          <Button variant="contained" onClick={handleForceUpdateBaasToken} disabled={isBaasTokenLoading}sx={{ backgroundColor: 'red', '&:hover': { backgroundColor: 'darkred' } }}>
            {isBaasTokenLoading ? <CircularProgress size={24} /> : 'Forza Aggiornamento Bass Token'}
          </Button>
        )}
        {baasTokenOperationResult && (
          <Typography sx={{ marginTop: 2, color: 'black' }}>
            {baasTokenOperationResult}
          </Typography>
        )}
      </Box>
{/* Fourth Tile */}
<Box sx={{ 
  border: '1px solid #ccc', 
  borderRadius: 2, 
  padding: 6, 
  marginBottom: 2, 
  display: 'flex', 
  flexDirection: 'column', 
  alignItems: 'center', 
  textAlign: 'center' 
}}>
  <Typography variant="h4" gutterBottom sx={{ color: 'black',marginBottom: '3rem' }}>
    Pulisci transazioni Epay non confermate
  </Typography>
  {!cleanupEpayOtpSent ? (
    <Button variant="contained" onClick={handleCleanupEpaySendOTP} disabled={isCleanupEpayLoading}>
      {isCleanupEpayLoading ? <CircularProgress size={24} /> : 'Manda codice OTP'}
    </Button>
  ) : !cleanupEpayOtpVerified ? (
    <>
      <TextField
        value={cleanupEpayOtpCode}
        onChange={(e) => setCleanupEpayOtpCode(e.target.value.replace(/[^0-9]/g, '').slice(0, 6))}
        placeholder="Inserisci OTP"
        inputProps={{
          maxLength: 6,
          style: { 
            textAlign: 'center', 
            letterSpacing: '0.5em',
            font: 'monospace',
            fontSize: '1.5em'
          }
        }}
        sx={{ my: 2, width: '200px' }}
      />
      <Button variant="contained" onClick={handleCleanupEpayVerifyOTP} disabled={cleanupEpayOtpCode.length !== 6 || isCleanupEpayLoading}>
        {isCleanupEpayLoading ? <CircularProgress size={24} /> : 'Verifica OTP code'}
      </Button>
    </>
  ) : (
    <Button variant="contained" onClick={handleCleanupEpayTransactions} disabled={isCleanupEpayLoading}sx={{ backgroundColor: 'red', '&:hover': { backgroundColor: 'darkred' } }}>
      {isCleanupEpayLoading ? <CircularProgress size={24} /> : 'Pulisci transazioni Epay non confermate'}
    </Button>
  )}
  {cleanupEpayOperationResult && (
    <Typography sx={{ marginTop: 2, color: 'black' }}>
      {cleanupEpayOperationResult}
    </Typography>
  )}
</Box>

{/* Fifth Tile */}
<Box sx={{ 
  border: '1px solid #ccc', 
  borderRadius: 2, 
  padding: 6, 
  marginBottom: 2, 
  display: 'flex', 
  flexDirection: 'column', 
  alignItems: 'center', 
  textAlign: 'center' 
}}>
  <Typography variant="h4" gutterBottom sx={{ color: 'black',marginBottom: '3rem' }}>
    Aggiornamento Conti Bancari
  </Typography>
  {!bankAccountsOtpSent ? (
    <Button variant="contained" onClick={handleBankAccountsSendOTP} disabled={isBankAccountsLoading}>
      {isBankAccountsLoading ? <CircularProgress size={24} /> : 'Manda codice OTP'}
    </Button>
  ) : !bankAccountsOtpVerified ? (
    <>
      <TextField
        value={bankAccountsOtpCode}
        onChange={(e) => setBankAccountsOtpCode(e.target.value.replace(/[^0-9]/g, '').slice(0, 6))}
        placeholder="Inserisci OTP"
        inputProps={{
          maxLength: 6,
          style: { 
            textAlign: 'center', 
            letterSpacing: '0.5em',
            font: 'monospace',
            fontSize: '1.5em'
          }
        }}
        sx={{ my: 2, width: '200px' }}
      />
      <Button variant="contained" onClick={handleBankAccountsVerifyOTP} disabled={bankAccountsOtpCode.length !== 6 || isBankAccountsLoading}>
        {isBankAccountsLoading ? <CircularProgress size={24} /> : 'Verifica OTP code'}
      </Button>
    </>
  ) : (
    <Button 
      variant="contained" 
      onClick={handleMaterialiseBankAccountBalances} 
      disabled={isBankAccountsLoading}
      sx={{ backgroundColor: 'red', '&:hover': { backgroundColor: 'darkred' } }}
    >
      {isBankAccountsLoading ? <CircularProgress size={24} /> : 'Aggiornamento Conti Bancari'}
    </Button>
  )}
  {bankAccountsOperationResult && (
    <Typography sx={{ marginTop: 2, color: 'black' }}>
      {bankAccountsOperationResult}
      {updatedAccountsCount !== null && (
        <span> Numero di conti aggiornati: {updatedAccountsCount}</span>
      )}
    </Typography>
  )}
</Box>

{/* Sixth Tile */}
<Box sx={{ 
  border: '1px solid #ccc', 
  borderRadius: 2, 
  padding: 6, 
  marginBottom: 2, 
  display: 'flex', 
  flexDirection: 'column', 
  alignItems: 'center', 
  textAlign: 'center' 
}}>
  <Typography variant="h4" gutterBottom sx={{ color: 'black',marginBottom: '3rem' }}>
    Rimuovi Epay Fido Blocker
  </Typography>
  {!epayFidoOtpSent ? (
    <Button variant="contained" onClick={handleEpayFidoSendOTP} disabled={isEpayFidoLoading}>
      {isEpayFidoLoading ? <CircularProgress size={24} /> : 'Manda codice OTP'}
    </Button>
  ) : !epayFidoOtpVerified ? (
    <>
      <TextField
        value={epayFidoOtpCode}
        onChange={(e) => setEpayFidoOtpCode(e.target.value.replace(/[^0-9]/g, '').slice(0, 6))}
        placeholder="Inserisci OTP"
        inputProps={{
          maxLength: 6,
          style: { 
            textAlign: 'center', 
            letterSpacing: '0.5em',
            font: 'monospace',
            fontSize: '1.5em'
          }
        }}
        sx={{ my: 2, width: '200px' }}
      />
      <Button variant="contained" onClick={handleEpayFidoVerifyOTP} disabled={epayFidoOtpCode.length !== 6 || isEpayFidoLoading}>
        {isEpayFidoLoading ? <CircularProgress size={24} /> : 'Verifica OTP code'}
      </Button>
    </>
  ) : (
    <Button 
      variant="contained" 
      onClick={handleRemoveEpayFidoBlocker} 
      disabled={isEpayFidoLoading}
      sx={{ backgroundColor: 'red', '&:hover': { backgroundColor: 'darkred' } }}
    >
      {isEpayFidoLoading ? <CircularProgress size={24} /> : 'Rimuovi Epay Fido Blocker'}
    </Button>
  )}
  {epayFidoOperationResult && (
    <Typography sx={{ marginTop: 2, color: 'black' }}>
      {epayFidoOperationResult}
      {epayFidoDetails && (
        <><br />{epayFidoDetails}</>
      )}
    </Typography>
  )}
</Box>

{/* Seventh Tile */}
<Box sx={{ 
  border: '1px solid #ccc', 
  borderRadius: 2, 
  padding: 6, 
  marginBottom: 2, 
  display: 'flex', 
  flexDirection: 'column', 
  alignItems: 'center', 
  textAlign: 'center' 
}}>
  <Typography variant="h4" gutterBottom sx={{ color: 'black',marginBottom: '3rem' }}>
    Mostra I dati finanziari per Admin
  </Typography>
  {!adminFinancialsOtpSent ? (
    <Button variant="contained" onClick={handleAdminFinancialsSendOTP} disabled={isAdminFinancialsLoading}>
      {isAdminFinancialsLoading ? <CircularProgress size={24} /> : 'Manda codice OTP'}
    </Button>
  ) : !adminFinancialsOtpVerified ? (
    <>
      <TextField
        value={adminFinancialsOtpCode}
        onChange={(e) => setAdminFinancialsOtpCode(e.target.value.replace(/[^0-9]/g, '').slice(0, 6))}
        placeholder="Inserisci OTP"
        inputProps={{
          maxLength: 6,
          style: { 
            textAlign: 'center', 
            letterSpacing: '0.5em',
            font: 'monospace',
            fontSize: '1.5em'
          }
        }}
        sx={{ my: 2, width: '200px' }}
      />
      <Button variant="contained" onClick={handleAdminFinancialsVerifyOTP} disabled={adminFinancialsOtpCode.length !== 6 || isAdminFinancialsLoading}>
        {isAdminFinancialsLoading ? <CircularProgress size={24} /> : 'Verifica OTP code'}
      </Button>
    </>
  ) : (
    <>
      <TextField
        label="Data Inizio (YYYY-MM-DD)"
        type="date"
        value={dateStart}
        onChange={(e) => setDateStart(e.target.value)}
        InputLabelProps={{
          shrink: true,
        }}
        sx={{ my: 1, width: '200px' }}
      />
      <TextField
        label="Data Fine (YYYY-MM-DD)"
        type="date"
        value={dateEnd}
        onChange={(e) => setDateEnd(e.target.value)}
        InputLabelProps={{
          shrink: true,
        }}
        sx={{ my: 1, width: '200px' }}
      />
      <Button 
        variant="contained" 
        onClick={handleMaterialiseAdminFinancials} 
        disabled={isAdminFinancialsLoading || !dateStart || !dateEnd}
        sx={{ backgroundColor: 'red', '&:hover': { backgroundColor: 'darkred' }, my: 1 }}
      >
        {isAdminFinancialsLoading ? <CircularProgress size={24} /> : 'Mostra I dati finanziari per Admin'}
      </Button>
    </>
  )}

{financialData && (
  <Box sx={{ mt: 2, width: '100%', maxWidth: '800px' }}> // Increased maxWidth
    <TextField
      value={financialData}
      multiline
      rows={10} // Increased number of rows
      fullWidth
      InputProps={{
        readOnly: true,
        style: { fontSize: '0.9rem' }, // Slightly smaller font size for more content
        endAdornment: (
          <Button onClick={handleCopyFinancialData}>
            <ContentCopyIcon />
          </Button>
        ),
      }}
      sx={{
        '& .MuiInputBase-root': {
          maxHeight: '400px', // Set a max height
          overflowY: 'auto', // Make it scrollable
        },
      }}
    />
  </Box>
)}
</Box>

{/* Eighth Tile */}
<Box sx={{ 
  border: '1px solid #ccc', 
  borderRadius: 2, 
  padding: 6, 
  marginBottom: 2, 
  display: 'flex', 
  flexDirection: 'column', 
  alignItems: 'center', 
  textAlign: 'center' 
}}>
  <Typography variant="h4" gutterBottom sx={{ color: 'black' ,marginBottom: '3rem'}}>
    Confronta Transizioni Epay
  </Typography>
  {!epayCompareOtpSent ? (
    <Button variant="contained" onClick={handleEpayCompareSendOTP} disabled={isEpayCompareLoading}>
      {isEpayCompareLoading ? <CircularProgress size={24} /> : 'Manda codice OTP'}
    </Button>
  ) : !epayCompareOtpVerified ? (
    <>
      <TextField
        value={epayCompareOtpCode}
        onChange={(e) => setEpayCompareOtpCode(e.target.value.replace(/[^0-9]/g, '').slice(0, 6))}
        placeholder="Inserisci OTP"
        inputProps={{
          maxLength: 6,
          style: { 
            textAlign: 'center', 
            letterSpacing: '0.5em',
            font: 'monospace',
            fontSize: '1.5em'
          }
        }}
        sx={{ my: 2, width: '200px' }}
      />
      <Button variant="contained" onClick={handleEpayCompareVerifyOTP} disabled={epayCompareOtpCode.length !== 6 || isEpayCompareLoading}>
        {isEpayCompareLoading ? <CircularProgress size={24} /> : 'Verifica OTP code'}
      </Button>
    </>
  ) : (
    <>
      <TextField
        multiline
        rows={10}
        value={epayCompareContent}
        onChange={handleEpayCompareContentChange}
        placeholder="Incolla qui il contenuto del file CSV"
        fullWidth
        sx={{ mb: 2 }}
      />
      <Button 
        variant="contained" 
        onClick={handleCompareEpayTransactions} 
        disabled={isEpayCompareLoading || !epayCompareContent.trim()}
        sx={{ backgroundColor: 'red', '&:hover': { backgroundColor: 'darkred' }, mt: 2 }}
      >
        {isEpayCompareLoading ? <CircularProgress size={24} /> : 'Confronta Transizioni Epay'}
      </Button>
    </>
  )}
 {epayCompareData && (
  <Box sx={{ mt: 4, width: '100%', height: '60vh', display: 'flex', flexDirection: 'column' }}>
    <Typography variant="h6" gutterBottom>
      Risultati del confronto:
    </Typography>
    <Paper 
      elevation={3} 
      sx={{ 
        flex: 1, 
        overflow: 'auto', 
        padding: 2,
        fontFamily: 'monospace',
        whiteSpace: 'pre-wrap',
        wordBreak: 'break-word',
        fontSize: '0.9rem',
        lineHeight: 1.5
      }}
    >
      {epayCompareData}
    </Paper>
    <Button 
      variant="contained" 
      startIcon={<ContentCopyIcon />} 
      onClick={handleCopyEpayCompareData}
      sx={{ mt: 2, alignSelf: 'flex-end' }}
    >
      Copia Risultati
    </Button>
  </Box>
)}
 

  {epayCompareOperationResult && (
    <Typography sx={{ marginTop: 2, color: 'black'}}>
      {epayCompareOperationResult}
    </Typography>
  )}
</Box>
{/* Ninth Tile */}
<Box sx={{ 
  border: '1px solid #ccc', 
  borderRadius: 2, 
  padding: 6, 
  marginBottom: 2, 
  display: 'flex', 
  flexDirection: 'column', 
  alignItems: 'center', 
  textAlign: 'center' 
}}>
  <Typography variant="h4" gutterBottom sx={{ color: 'black', marginBottom: '3rem' }}>
    Rimborso Cliente
  </Typography>
  {!refundOtpSent ? (
    <Button variant="contained" onClick={handleRefundSendOTP} disabled={isRefundLoading}>
      {isRefundLoading ? <CircularProgress size={24} /> : 'Manda codice OTP'}
    </Button>
  ) : !refundOtpVerified ? (
    <>
<TextField
  value={refundOtpCode}
  onChange={(e) => setRefundOtpCode(e.target.value.replace(/[^0-9]/g, '').slice(0, 6))}
  placeholder="Inserisci OTP"
  inputProps={{
    maxLength: 6,
    style: { 
      textAlign: 'center', 
      letterSpacing: '0.5em',
      font: 'monospace',
      fontSize: '1.5em'
    }
  }}
  sx={{ my: 2, width: '200px' }}
/>
<Button 
  variant="contained" 
  onClick={handleRefundVerifyOTP} 
  disabled={refundOtpCode.length !== 6 || isRefundLoading}
>
  {isRefundLoading ? <CircularProgress size={24} /> : 'Verifica OTP code'}
</Button>
    
    </>
  ) : (
    <>
      <TextField
        label="ID Cliente"
        value={customerId}
        onChange={(e) => setCustomerId(e.target.value)}
        fullWidth
        sx={{ mb: 2 }}
      />
      <TextField
        label="Importo"
        type="number"
        value={refundAmount}
        onChange={(e) => setRefundAmount(e.target.value)}
        fullWidth
        sx={{ mb: 2 }}
      />
      <TextField
        label="Valuta"
        value={refundCurrency}
        onChange={(e) => setRefundCurrency(e.target.value)}
        fullWidth
        sx={{ mb: 2 }}
      />
      <TextField
        label="Descrizione"
        value={refundDescription}
        onChange={(e) => setRefundDescription(e.target.value)}
        fullWidth
        sx={{ mb: 2 }}
      />
      <Button 
        variant="contained" 
        onClick={handleRefundCustomer} 
        disabled={isRefundLoading}
        sx={{ backgroundColor: 'red', '&:hover': { backgroundColor: 'darkred' } }}
      >
        {isRefundLoading ? <CircularProgress size={24} /> : 'Effettua Rimborso'}
      </Button>
    </>
  )}
  {refundOperationResult && (
    <Typography sx={{ marginTop: 2, color: 'black' }}>
      {refundOperationResult}
    </Typography>
  )}
</Box>



{/* Tenth Tile */}
<Box sx={{ 
  border: '1px solid #ccc', 
  borderRadius: 2, 
  padding: 6, 
  marginBottom: 2, 
  display: 'flex', 
  flexDirection: 'column', 
  alignItems: 'center', 
  textAlign: 'center' 
}}>
  <Typography variant="h4" gutterBottom sx={{ color: 'red',marginBottom: '3rem' }}>
    Disconnetti tutti gli utenti
  </Typography>
  <Typography variant="body1" sx={{ color: 'red', marginBottom: 2 }}>
    Richiesta pericolosa, chiedere a Dario prima di attivare, anche I Super admin verranno disconnessi
  </Typography>
  {!disconnectAllOtpSent ? (
    <Button variant="contained" onClick={handleDisconnectAllSendOTP} disabled={isDisconnectAllLoading}>
      {isDisconnectAllLoading ? <CircularProgress size={24} /> : 'Manda codice OTP'}
    </Button>
  ) : !disconnectAllOtpVerified ? (
    <>
      <TextField
        value={disconnectAllOtpCode}
        onChange={(e) => setDisconnectAllOtpCode(e.target.value.replace(/[^0-9]/g, '').slice(0, 6))}
        placeholder="Inserisci OTP"
        inputProps={{
          maxLength: 6,
          style: { 
            textAlign: 'center', 
            letterSpacing: '0.5em',
            font: 'monospace',
            fontSize: '1.5em'
          }
        }}
        sx={{ my: 2, width: '200px' }}
      />
      <Button variant="contained" onClick={handleDisconnectAllVerifyOTP} disabled={disconnectAllOtpCode.length !== 6 || isDisconnectAllLoading}>
        {isDisconnectAllLoading ? <CircularProgress size={24} /> : 'Verifica OTP code'}
      </Button>
    </>
  ) : (
    <Button 
      variant="contained" 
      onClick={handleDisconnectAllUsers} 
      disabled={isDisconnectAllLoading}
      sx={{ backgroundColor: 'red', '&:hover': { backgroundColor: 'darkred' } }}
    >
      {isDisconnectAllLoading ? <CircularProgress size={24} /> : 'Disconnetti tutti gli utenti'}
    </Button>
  )}
  {disconnectAllOperationResult && (
    <Typography sx={{ marginTop: 2, color: 'black' }}>
      {disconnectAllOperationResult}
    </Typography>
  )}
</Box>



    </Box>
  );
}

export default Page_SuperAdminOperations;