import React from 'react';
import { Dialog, DialogTitle, DialogContent, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography } from '@mui/material';

const Modal_BlockedTransactions = ({ open, handleClose, transactions }) => {
  const safeTransactions = Array.isArray(transactions) ? transactions : [];

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>Transazioni bancarie bloccate</DialogTitle>
      <DialogContent>
        {safeTransactions.length > 0 ? (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID Transazione</TableCell>
                  <TableCell>Data</TableCell>
                  <TableCell>Importo</TableCell>
                  <TableCell>Mittente</TableCell>
                  <TableCell>Destinatario</TableCell>
                  <TableCell>Stato precedente</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {safeTransactions.map((transaction) => (
                  <TableRow key={transaction.bank_transfer_id}>
                    <TableCell>{transaction.bank_transfer_id}</TableCell>
                    <TableCell>{new Date(transaction.transaction_date_time).toLocaleString('it-IT')}</TableCell>
                    <TableCell>{new Intl.NumberFormat('it-IT', { style: 'currency', currency: transaction.currency }).format(transaction.amount)}</TableCell>
                    <TableCell>{transaction.sender_name}</TableCell>
                    <TableCell>{transaction.account_to}</TableCell>
                    <TableCell>{transaction.change_from_status}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Typography>Nessuna transazione bloccata da visualizzare.</Typography>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default Modal_BlockedTransactions;