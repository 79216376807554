import React, { useState, useEffect } from 'react';
import { fetchTwilioStatus, fetchSendGridStatus } from './Util_API_calls';
import { Card, CardContent, Typography, Grid, Button, Box } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import WarningIcon from '@mui/icons-material/Warning';
import Modal_SMStwilio from './Modal_SMStwilio';
import Modal_EmailSendGrid from './Modal_EmailSendGrid';

const Page_Supplies = () => {
  const [twilioStatus, setTwilioStatus] = useState(null);
  const [sendGridStatus, setSendGridStatus] = useState(null);
  const [error, setError] = useState(null);
  const [isSMSModalOpen, setIsSMSModalOpen] = useState(false);
  const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);

  useEffect(() => {
    const fetchStatuses = async () => {
      try {
        const twilio = await fetchTwilioStatus();
        setTwilioStatus(twilio);
        const sendGrid = await fetchSendGridStatus();
        setSendGridStatus(sendGrid);
      } catch (error) {
        console.error('Error fetching statuses:', error);
        setError('Failed to fetch services status. Please try again later.');
      }
    };

    fetchStatuses();
  }, []);

  const getOverallStatus = (services) => {
    if (services.every(service => service.status === 'operational')) {
      return { status: 'operational', text: 'Tutto ok', icon: <CheckCircleIcon style={{ color: 'green', fontSize: 40 }} /> };
    } else if (services.some(service => service.status === 'degraded_performance')) {
      return { status: 'degraded', text: 'Parzialmente funzionante', icon: <WarningIcon style={{ color: 'orange', fontSize: 40 }} /> };
    } else {
      return { status: 'down', text: 'Non funzionante', icon: <ErrorIcon style={{ color: 'red', fontSize: 40 }} /> };
    }
  };

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  const smsStatus = twilioStatus ? getOverallStatus(twilioStatus.sms) : null;
  const emailStatus = sendGridStatus ? getOverallStatus(sendGridStatus) : null;

  return (
    <div style={{ padding: '20px' }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Box display="flex" flexDirection="column" alignItems="center" textAlign="center">
                <Typography variant="h5" gutterBottom>Invio SMS (Twilio)</Typography>
                {smsStatus ? (
                  <>
                    <Box display="flex" alignItems="center" justifyContent="center" mb={2}>
                      {smsStatus.icon}
                      <Typography variant="h6" style={{ marginLeft: '10px' }}>
                        {smsStatus.text}
                      </Typography>
                    </Box>
                    {smsStatus.status !== 'operational' && (
                      <Button onClick={() => setIsSMSModalOpen(true)} color="primary" variant="contained">
                        Vedi dettagli
                      </Button>
                    )}
                  </>
                ) : (
                  <Typography>Loading status...</Typography>
                )}
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Box display="flex" flexDirection="column" alignItems="center" textAlign="center">
                <Typography variant="h5" gutterBottom>Invio Email (SendGrid)</Typography>
                {emailStatus ? (
                  <>
                    <Box display="flex" alignItems="center" justifyContent="center" mb={2}>
                      {emailStatus.icon}
                      <Typography variant="h6" style={{ marginLeft: '10px' }}>
                        {emailStatus.text}
                      </Typography>
                    </Box>
                    {emailStatus.status !== 'operational' && (
                      <Button onClick={() => setIsEmailModalOpen(true)} color="primary" variant="contained">
                        Vedi dettagli
                      </Button>
                    )}
                  </>
                ) : (
                  <Typography>Loading status...</Typography>
                )}
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      {twilioStatus && (
        <Modal_SMStwilio
          open={isSMSModalOpen}
          onClose={() => setIsSMSModalOpen(false)}
          services={twilioStatus.sms}
        />
      )}
      {sendGridStatus && (
        <Modal_EmailSendGrid
          open={isEmailModalOpen}
          onClose={() => setIsEmailModalOpen(false)}
          services={sendGridStatus}
        />
      )}
    </div>
  );
};

export default Page_Supplies;