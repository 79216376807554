import React from 'react';
import { Modal, Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TableSortLabel } from '@mui/material';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  maxHeight: '80vh', // Ensure the modal cannot be higher than 80% of the viewport height
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  overflow: 'auto', // Allow the modal itself to scroll if content exceeds maxHeight
};

const tableContainerStyle = {
  maxHeight: '60vh', // Ensure the table container cannot be higher than 60% of the viewport height
  overflowY: 'auto', // Allow vertical scrolling inside the table container
};

const Modal_NegativeBalanceUsers = ({ open, handleClose, users }) => {
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('last_known_balance');

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const sortedUsers = [...users].sort((a, b) => {
    if (orderBy === 'last_known_balance') {
      return order === 'asc' ? a.last_known_balance - b.last_known_balance : b.last_known_balance - a.last_known_balance;
    }
    if (orderBy === 'first_name') {
      return order === 'asc' ? a.first_name.localeCompare(b.first_name) : b.first_name.localeCompare(a.first_name);
    }
    if (orderBy === 'last_name') {
      return order === 'asc' ? a.last_name.localeCompare(b.last_name) : b.last_name.localeCompare(a.last_name);
    }
    return 0;
  });

  return (
    <Modal open={open} onClose={handleClose} aria-labelledby="modal-title" aria-describedby="modal-description">
      <Box sx={style}>
        <Typography id="modal-title" variant="h6" component="h2">
          Utenti con bilancio negativo
        </Typography>
        <TableContainer component={Paper} sx={tableContainerStyle} style={{ marginTop: '20px' }}>
          <Table size="small" aria-label="negative balance users">
            <TableHead>
              <TableRow>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === 'first_name'}
                    direction={orderBy === 'first_name' ? order : 'asc'}
                    onClick={(event) => handleRequestSort(event, 'first_name')}
                  >
                    First Name
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === 'last_name'}
                    direction={orderBy === 'last_name' ? order : 'asc'}
                    onClick={(event) => handleRequestSort(event, 'last_name')}
                  >
                    Last Name
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === 'last_known_balance'}
                    direction={orderBy === 'last_known_balance' ? order : 'asc'}
                    onClick={(event) => handleRequestSort(event, 'last_known_balance')}
                  >
                    Last Known Balance
                  </TableSortLabel>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedUsers.map((user, index) => (
                <TableRow key={index}>
                  <TableCell>{user.first_name}</TableCell>
                  <TableCell>{user.last_name}</TableCell>
                  <TableCell>{new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'EUR' }).format(user.last_known_balance)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Modal>
  );
};

export default Modal_NegativeBalanceUsers;
