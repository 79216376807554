import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, TextField, CircularProgress, Typography } from '@mui/material';
import { send_email } from './Util_API_calls';

const Modal_SendEmail = ({ isOpen, onClose, userEmail }) => {
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const handleSendEmail = async () => {
    setIsProcessing(true);
    setErrorMessage('');
    setSuccessMessage('');

    // Basic validation checks
    if (!userEmail) {
      setErrorMessage('L\'email dell\'utente non esiste.');
      setIsProcessing(false);
      return;
    }

    if (!subject || !message) {
      setErrorMessage('Soggetto e messaggio sono obbligatori.');
      setIsProcessing(false);
      return;
    }

    try {
      const result = await send_email(subject, message, [userEmail]);
      if (result.status === 'success') {
        setSuccessMessage(result.data);
      } else {
        setErrorMessage(result.message || 'Failed to send email');
      }
    } catch (error) {
      setErrorMessage(error.message || 'Failed to send email');
    }
    setIsProcessing(false);
  };

  const handleClose = () => {
    setSubject('');
    setMessage('');
    setErrorMessage('');
    setSuccessMessage('');
    onClose();
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>Manda Email</DialogTitle>
      <DialogContent>
        {errorMessage && <Typography color="error">{errorMessage}</Typography>}
        {successMessage ? (
          <Typography variant="body1">{successMessage}</Typography>
        ) : (
          <>
            <TextField
              label="Oggetto"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Messaggio"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              fullWidth
              margin="normal"
              multiline
              rows={4}
            />
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} disabled={isProcessing}>
          {successMessage ? 'OK' : 'Cancel'}
        </Button>
        {!successMessage && (
          <Button onClick={handleSendEmail} color="primary" variant="contained" disabled={isProcessing}>
            {isProcessing ? <CircularProgress size={24} color="inherit" /> : 'Invia'}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default Modal_SendEmail;
