import React, { useEffect, useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, CircularProgress, Typography, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { fetch_customer_cards } from './Util_API_calls';

const Modal_CustomerCards = ({ isOpen, onClose, customer_id, onShowCardTransactions }) => {
  const [accountsData, setAccountsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const fetchDetails = async () => {
      setLoading(true);
      setErrorMessage('');
      console.log("Fetching customer cards for customer_id:", customer_id);
      
      if (!customer_id) {
        console.error("Missing customer_id");
        setErrorMessage('ID cliente mancante');
        setLoading(false);
        return;
      }

      try {
        const result = await fetch_customer_cards(customer_id);
        console.log("Customer cards API response:", result);
        
        if (result.status === 'success' && Array.isArray(result.accounts)) {
          const filteredAccounts = result.accounts.filter(account => account.card_id);
          console.log("Filtered accounts:", filteredAccounts);
          setAccountsData(filteredAccounts);
        } else {
          console.error("Unexpected API response structure:", result);
          setErrorMessage(result.message || 'Errore nel recupero dei dati delle carte');
        }
      } catch (error) {
        console.error("Error fetching customer cards:", error);
        setErrorMessage('Errore nella richiesta dei dati delle carte');
      }
      setLoading(false);
    };

    if (isOpen) {
      fetchDetails();
    }
  }, [isOpen, customer_id]);

  const handleClose = () => {
    setAccountsData([]);
    setErrorMessage('');
    onClose();
  };

  const handleShowCardTransactions = (card_id) => {
    console.log("Triggering card transactions for card_id:", card_id);
    if (onShowCardTransactions && typeof onShowCardTransactions === 'function') {
      onShowCardTransactions(card_id);
    } else {
      console.warn('onShowCardTransactions is not provided or is not a function');
    }
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>Dettagli Carte Cliente </DialogTitle>
      <DialogContent>
        {loading ? (
          <CircularProgress />
        ) : errorMessage ? (
          <Typography color="error">{errorMessage}</Typography>
        ) : accountsData.length === 0 ? (
          <Typography>Non ci sono carte al momento</Typography>
        ) : (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID Carta</TableCell>
                <TableCell>Numero IBAN</TableCell>
                <TableCell>Saldo</TableCell>
                <TableCell>Valuta</TableCell>
                <TableCell>Tipologia carta</TableCell>
                <TableCell>Numero di tracciamento</TableCell>
                <TableCell>Data di creazione</TableCell>
                <TableCell>Data di aggiornamento</TableCell>
                <TableCell>Azioni</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {accountsData.map((account, index) => (
                <TableRow key={index}>
                  <TableCell>{account.card_id}</TableCell>
                  <TableCell>{account.account_number}</TableCell>
                  <TableCell>{account.last_known_balance}</TableCell>
                  <TableCell>{account.currency}</TableCell>
                  <TableCell>{account.card_type || 'N/A'}</TableCell>
                  <TableCell>{account.card_type === 'virtual' ? 'Non disponibile' : (account.tracking_id || 'N/A')}</TableCell>
                  <TableCell>{account.created_at || 'N/A'}</TableCell>
                  <TableCell>{account.updated_at || 'N/A'}</TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleShowCardTransactions(account.card_id)}
                    >
                      Mostra transazioni carta
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Chiudi
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Modal_CustomerCards;