import React, { useState, useEffect } from 'react';
import {  ThemeProvider } from '@mui/material';
import { theme } from './ReactStyles';

function Page_Charts({ onDataChange, ...props }) {


  return (
    <div style={{ marginLeft: 'auto', marginRight: 'auto', maxWidth: '1500px' }}>
      <ThemeProvider theme={theme}>

      </ThemeProvider>
    </div >
  );
}

export default Page_Charts;