import React, { useState } from 'react';
import { ThemeProvider, Button, TextareaAutosize, CircularProgress } from '@mui/material';
import { theme } from './ReactStyles';
import { force_update_baas_token } from './Util_API_calls';

function Page_Unlimit({ onDataChange, ...props }) {
  const [apiResponse, setApiResponse] = useState('');
  const [isApiCalling, setIsApiCalling] = useState(false);
  const [buttonClicked, setButtonClicked] = useState(false); // New state to track if button has been clicked

  const handleButtonClick = async () => {
    setButtonClicked(true);
    setIsApiCalling(true);
    const response = await force_update_baas_token();
    setApiResponse(JSON.stringify(response, null, 2));
    setIsApiCalling(false);
  };

  return (
    <div style={{ marginLeft: 'auto', marginRight: 'auto', paddingTop: '20px' }}>
      <ThemeProvider theme={theme}>
        <Button 
          variant="contained" 
          color="primary" 
          onClick={handleButtonClick}
          disabled={isApiCalling}
        >
          Update BaaS Token
        </Button>
        {isApiCalling ? (
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
            <CircularProgress />
          </div>
        ) : buttonClicked && (
          <TextareaAutosize
            aria-label="API response"
            minRows={5}
            placeholder="API response will appear here..."
            style={{ width: '100%', marginTop: '20px' }}
            value={apiResponse}
          />
        )}
      </ThemeProvider>
    </div>
  );
}

export default Page_Unlimit;
