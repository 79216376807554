import React, { useState, useEffect } from 'react';
import { Grid, Card, CardContent, Typography, ThemeProvider, FormControl, InputLabel, Select, MenuItem, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { theme } from './ReactStyles';
import { fetchMasterAccountBalance, fetchMasterAccountDiagnostics } from './Util_API_calls';

function Page_Masteraccount() {
  const [masterAccountBalance, setMasterAccountBalance] = useState(null);
  const [month, setMonth] = useState('');
  const [year, setYear] = useState('');
  const [diagnosticsData, setDiagnosticsData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const balanceResult = await fetchMasterAccountBalance();
      console.log('Fetched Master Account Balance:', balanceResult); // Log the fetched data
      if (balanceResult.status === 'success') {
        setMasterAccountBalance(balanceResult.data);
      } else {
        console.error('Error fetching master account balance:', balanceResult.message);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const currentDate = new Date();
    const currentMonth = String(currentDate.getMonth() + 1).padStart(2, '0');
    const currentYear = String(currentDate.getFullYear());

    setMonth(currentMonth);
    setYear(currentYear);

    // Fetch the diagnostics data for the current month and year
    const fetchData = async () => {
      const diagnosticsResult = await fetchMasterAccountDiagnostics(currentMonth, currentYear);
      console.log('Fetched Diagnostics Data:', diagnosticsResult); // Log the fetched data
      if (diagnosticsResult.status === 'success') {
        setDiagnosticsData(diagnosticsResult.data);
      } else {
        console.error('Error fetching diagnostics data:', diagnosticsResult.message);
      }
    };

    fetchData();
  }, []);

  const handleMonthChange = (event) => {
    setMonth(event.target.value);
  };

  const handleYearChange = (event) => {
    setYear(event.target.value);
  };

  const handleFetchDiagnostics = async () => {
    if (!month || !year) {
      alert('Please select both month and year.');
      return;
    }
    const result = await fetchMasterAccountDiagnostics(month, year);
    console.log('Fetched Diagnostics Data:', result); // Log the fetched data
    if (result.status === 'success') {
      setDiagnosticsData(result.data);
    } else {
      console.error('Error fetching diagnostics data:', result.message);
    }
  };

  if (!masterAccountBalance) {
    return <div>Loading...</div>;
  }

  // Safely access the balance data
  const { balance } = masterAccountBalance;
  const amount = balance?.amount ?? 'N/A';
  const currency = balance?.currency ?? 'N/A';

  return (
    <div style={{ marginLeft: 'auto', marginRight: 'auto', maxWidth: '1500px' }}>
      <ThemeProvider theme={theme}>
        <Grid container spacing={3} style={{ paddingTop: '20px' }}>
          <Grid item xs={12}>
            <Card style={{ height: '180px', boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)' }}>
              <CardContent style={{ textAlign: 'center' }}>
                <Typography variant="h5" component="div" style={{ color: '#339AD8' }}>
                  Bilancio Master Account
                </Typography>
                <Typography variant="h3" component="div" style={{ marginTop: '30px' }}>
                  {`${amount} ${currency}`}
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Card style={{ height: 'auto', boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)' }}>
              <CardContent style={{ textAlign: 'center' }}>
                <Typography variant="h5" component="div" style={{ color: '#339AD8' }}>
                  Soldi Inaspettati Valore Totale
                </Typography>
                <FormControl variant="outlined" style={{ marginTop: '20px', minWidth: 200 }}>
                  <InputLabel id="select-month-label">Month</InputLabel>
                  <Select
                    labelId="select-month-label"
                    value={month}
                    onChange={handleMonthChange}
                    label="Month"
                  >
                    {[...Array(12).keys()].map(i => (
                      <MenuItem key={i + 1} value={String(i + 1).padStart(2, '0')}>
                        {String(i + 1).padStart(2, '0')}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl variant="outlined" style={{ marginTop: '20px', minWidth: 200, marginLeft: '20px' }}>
                  <InputLabel id="select-year-label">Year</InputLabel>
                  <Select
                    labelId="select-year-label"
                    value={year}
                    onChange={handleYearChange}
                    label="Year"
                  >
                    <MenuItem value="2023">2023</MenuItem>
                    <MenuItem value="2024">2024</MenuItem>
                  </Select>
                </FormControl>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleFetchDiagnostics}
                  style={{ marginTop: '20px', marginLeft: '20px' }}
                >
                  Mostra transizioni
                </Button>
                {diagnosticsData && (
                  <div style={{ marginTop: '30px' }}>
                    <Typography variant="h6" component="div">
                      Unexpected Money In Total Amount: {diagnosticsData.unexpected_money_in_total_amount} EUR
                    </Typography>
                    <TableContainer component={Paper} style={{ marginTop: '20px' }}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Transaction ID</TableCell>
                            <TableCell>Category</TableCell>
                            <TableCell>Date</TableCell>
                            <TableCell>Amount</TableCell>
                            <TableCell>Currency</TableCell>
                            <TableCell>Description</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {diagnosticsData.unexpected_money_in_transactions.map((transaction) => (
                            <TableRow key={transaction.transaction_id}>
                              <TableCell>{transaction.transaction_id}</TableCell>
                              <TableCell>{transaction.transaction_category}</TableCell>
                              <TableCell>{new Date(transaction.created_at).toLocaleString()}</TableCell>
                              <TableCell>{transaction.amount}</TableCell>
                              <TableCell>{transaction.currency}</TableCell>
                              <TableCell>{transaction.description}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                )}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </ThemeProvider>
    </div>
  );
}

export default Page_Masteraccount;
