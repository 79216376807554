import React, { useState, useEffect } from 'react';
import { theme } from './ReactStyles';
import { Grid, Card, CardContent, Typography, Button, Box, Table, TableBody, TableCell, TextField, TableHead, TableRow, Select, MenuItem, FormControl, InputLabel, ThemeProvider } from '@mui/material';
import { t, number_to_italian_currency } from './Util_format';
import TablePagination from '@mui/material/TablePagination';
import { fetchPageVolumes } from './Util_API_calls';
import { CircularProgress } from '@mui/material';
import * as XLSX from 'xlsx';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

function Page_Businessvolume({ onDataChange, ...props }) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [financialData, setFinancialData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [aggregateBy, setAggregateBy] = useState('day');
  const [dashboardData, setDashboardData] = useState(null);  // New state variable for dashboard data
  const [month, setMonth] = useState(new Date().toLocaleString('default', { month: 'long' }));
  const [year, setYear] = useState(new Date().getFullYear().toString());

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

  const handleMonthChange = (event) => setMonth(event.target.value);
  const handleYearChange = (event) => setYear(event.target.value);
  const handleAggregateChange = (event) => setAggregateBy(event.target.value);

  const exportToExcel = () => {
    const ws = XLSX.utils.json_to_sheet(financialData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Financial Data");
    XLSX.writeFile(wb, "financial_data.xlsx");
  };

  useEffect(() => {
    const fetchData = async () => {
      const result = await fetchPageVolumes('volume', '2023-01-01', '2030-01-01');
      if (result.status === 'success') {
        setDashboardData(result.financials);  // Store the entire financials object
      } else {
        console.error('Error fetching dashboard data:', result.message);
      }
    };  
    fetchData();
  }, []);
  

  const processFinancialData = () => {
    setLoading(true);
  
    if (!dashboardData) {
      setLoading(false);
      return;
    }
  
    let filteredData = [];
  
    const aggregateWeeklyData = (dailyData) => {
      const weeklyData = [];
      dailyData.forEach((data) => {
        const weekStart = new Date(data.date);
        weekStart.setDate(weekStart.getDate() - weekStart.getDay());
        const weekStartStr = weekStart.toISOString().split('T')[0];
  
        let week = weeklyData.find(w => w.date === weekStartStr);
        if (!week) {
          week = { ...data, date: weekStartStr };
          weeklyData.push(week);
        } else {
          Object.keys(data).forEach(key => {
            if (key !== 'date') {
              week[key] += data[key];
            }
          });
        }
      });
      return weeklyData;
    };
  
    const aggregateMonthlyData = (dailyData) => {
      const monthlyData = [];
      dailyData.forEach((data) => {
        const monthStart = data.date.substring(0, 7); // YYYY-MM
        let month = monthlyData.find(m => m.date === monthStart);
        if (!month) {
          month = { ...data, date: monthStart };
          monthlyData.push(month);
        } else {
          Object.keys(data).forEach(key => {
            if (key !== 'date') {
              month[key] += data[key];
            }
          });
        }
      });
      return monthlyData;
    };
  
    switch (aggregateBy) {
      case 'month':
        const monthlyData = aggregateMonthlyData(dashboardData.daily);
        filteredData = monthlyData.filter(data => data.date.startsWith(year));
        break;
      case 'week':
        const weeklyData = aggregateWeeklyData(dashboardData.daily);
        const currentDate = new Date();
        const currentDay = currentDate.getDay();
        const distanceToMonday = (currentDay + 6) % 7;
        const monday = new Date(currentDate);
        monday.setDate(currentDate.getDate() - distanceToMonday);
  
        const startOfWeek = new Date(monday);
        startOfWeek.setDate(monday.getDate() - ((monday.getDay() + 6) % 7));
        const endOfWeek = new Date(startOfWeek);
        endOfWeek.setDate(startOfWeek.getDate() + 6);
  
        filteredData = weeklyData.filter(data => {
          const dataDate = new Date(data.date);
          return dataDate >= startOfWeek && dataDate <= endOfWeek;
        });
        break;
      case 'day':
      default:
        const monthIndex = months.indexOf(month) + 1;
        const monthPadded = monthIndex.toString().padStart(2, '0');
        filteredData = dashboardData.daily.filter(data => data.date.startsWith(`${year}-${monthPadded}`));
        break;
    }
  
    setFinancialData(filteredData);
    setLoading(false);
  };
  
  

  // Use effect to call processFinancialData when aggregateBy, month, or year changes
  useEffect(() => {
    processFinancialData();
  }, [aggregateBy, month, year, dashboardData]);

  return (
    <div style={{ marginLeft: 'auto', marginRight: 'auto', marginTop: '30px' }}>
      <ThemeProvider theme={theme}>
        <FormControl variant="outlined" style={{ marginLeft: '20px' }}>
          <InputLabel id="aggregate-by-label">Aggregate By</InputLabel>
          <Select
            style={{ width: '350px' }}
            labelId="aggregate-by-label"
            id="aggregate-by-select"
            value={aggregateBy}
            onChange={handleAggregateChange}
            label="Aggregate By"
          >
            <MenuItem value="day">Day (precise)</MenuItem>
            <MenuItem value="month">Month (Value on last day of month)</MenuItem>
          </Select>
        </FormControl>
        <FormControl variant="outlined" style={{ marginLeft: '20px' }}>
          <InputLabel id="year-label">Year</InputLabel>
          <Select
            style={{ width: '200px' }}
            labelId="year-label"
            id="year-select"
            value={year}
            onChange={handleYearChange}
            label="Year"
          >
            {Array.from({ length: currentYear - 2023 + 1 }, (_, i) => 2023 + i).map(y => (
              <MenuItem key={y} value={y.toString()}>{y}</MenuItem>
            ))}
          </Select>
        </FormControl>
        {aggregateBy !== 'month' && (
          <FormControl variant="outlined" style={{ marginLeft: '20px' }}>
            <InputLabel id="month-label">Month</InputLabel>
            <Select
              style={{ width: '200px' }}
              labelId="month-label"
              id="month-select"
              value={month}
              onChange={handleMonthChange}
              label="Month"
            >
              {months.map((m, index) => (
                <MenuItem key={index} value={m}>{m}</MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        <FormControl>
          <Box>
            <Button
              color="primary"
              onClick={exportToExcel}
              style={{ margin: '10px' }}
            >
              <CloudDownloadIcon />
            </Button>
          </Box>
        </FormControl>
        {loading ? (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <CircularProgress />
          </div>
        ) : (
          <>
            <Table style={{ marginTop: '20px' }}>
              <TableHead>
                <TableRow>
                  <TableCell>Date</TableCell>
                  <TableCell>Login Quantity</TableCell>
                  <TableCell>KYC Initiated Quantity</TableCell>
                  <TableCell>Card Creation Physical White Quantity</TableCell>
                  <TableCell>Card Creation Physical Blue Quantity</TableCell>
                  <TableCell>Card Creation Virtual Quantity</TableCell>
                  <TableCell>Card Transactions Quantity SEPA (payments)</TableCell>
                  <TableCell>Card Transactions Quantity SEPA (transfers)</TableCell>
                  <TableCell>Card Transactions Quantity not SEPA</TableCell>
                  <TableCell>Bankaccount Transactions In and Out Quantity</TableCell>
                  <TableCell>Bankaccount Transactions Internal Out Quantity</TableCell>
                  <TableCell>Total Transacted Volume Cards</TableCell>
                  <TableCell>Total Transacted Volume Bankaccounts Internal</TableCell>
                  <TableCell>Total Transacted Volume Bankaccounts SEPA</TableCell>
                  <TableCell>Total Transacted Volume Bankaccounts SWIFT</TableCell>
                  <TableCell>Bankaccount Ownership Quantity Active</TableCell>
                  <TableCell>Bankaccount Ownership Quantity Dormant</TableCell>
                  <TableCell>Customers With Cards Quantity</TableCell>
                  <TableCell>Card Existing Quantity</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {financialData
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((data, index) => (
                    <TableRow key={index}>
                      <TableCell>{data.date}</TableCell>
                      <TableCell>{data.login_quantity}</TableCell>
                      <TableCell>{data.kyc_initiated_quantity}</TableCell>
                      <TableCell>{data.card_creation_physical_white_quantity}</TableCell>
                      <TableCell>{data.card_creation_physical_blue_quantity}</TableCell>
                      <TableCell>{data.card_creation_virtual_quantity}</TableCell>
                      <TableCell>{data.card_transactions_quantity_SEPA}</TableCell>
                      <TableCell>{data.card_transactions_quantity_not_SEPA}</TableCell>
                      <TableCell>{data.bankaccount_transactions_in_and_out_quantity}</TableCell>
                      <TableCell>{data.bankaccount_transactions_internal_out_quantity}</TableCell>
                      <TableCell>{number_to_italian_currency(data.total_transacted_volume_cards)}</TableCell>
                      <TableCell>{number_to_italian_currency(data.total_transacted_volume_bankaccounts_internal)}</TableCell>
                      <TableCell>{number_to_italian_currency(data.total_transacted_volume_bankaccounts_sepa)}</TableCell>
                      <TableCell>{number_to_italian_currency(data.total_transacted_volume_bankaccounts_swift)}</TableCell>
                      <TableCell>{data.bankaccount_ownership_quantity_active}</TableCell>
                      <TableCell>{data.bankaccount_ownership_quantity_dormant}</TableCell>
                      <TableCell>{data.customers_with_cards_quantity}</TableCell>
                      <TableCell>{data.card_existing_quantity}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            <TablePagination
              component="div"
              count={financialData.length}
              page={page}
              onPageChange={(event, newPage) => setPage(newPage)}
              rowsPerPage={rowsPerPage}
              labelRowsPerPage={'Rows per page'}
              onRowsPerPageChange={(event) => {
                setRowsPerPage(parseInt(event.target.value, 10));
                setPage(0);
              }}
            />
          </>
        )}
      </ThemeProvider>
    </div>
  );
}

export default Page_Businessvolume;
