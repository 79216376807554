// Page_Customers.js
import React, { useState, useEffect } from 'react';
import { theme } from './ReactStyles';
import { Grid, TextField, Table, TableBody, TableCell, TableHead, TableRow, CircularProgress, TablePagination, ThemeProvider, TableSortLabel, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { get_basic_user_list, update_user_email } from './Util_API_calls';
import kyc_statuses from './config/kyc_statuses.json';
import Modal_CustomerOptions from './Modal_CustomerOptions';
import Modal_UserDetails from './Modal_UserDetails';
import Modal_CustomerDetail from './Modal_CustomerDetail';
import Modal_CustomerAccounts from './Modal_CustomerAccounts';
import Modal_CustomerTransactions from './Modal_CustomerTransactions';
import Modal_CustomerCards from './Modal_CustomerCards';
import Modal_CardTransactions from './Modal_CardTransactions';
import Modal_KYCStatus from './Modal_KYCStatus';
import Modal_SendEmail from './Modal_SendEmail';

function Page_Customers({ onDataChange, ...props }) {
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [isCustomerOptionsModalOpen, setIsCustomerOptionsModalOpen] = useState(false);
  const [isUserDetailsModalOpen, setIsUserDetailsModalOpen] = useState(false);
  const [isCustomerDetailModalOpen, setIsCustomerDetailModalOpen] = useState(false);
  const [isCustomerAccountsModalOpen, setIsCustomerAccountsModalOpen] = useState(false);
  const [isCustomerTransactionsModalOpen, setIsCustomerTransactionsModalOpen] = useState(false);
  const [isCustomerCardsModalOpen, setIsCustomerCardsModalOpen] = useState(false);
  const [isCardTransactionsModalOpen, setIsCardTransactionsModalOpen] = useState(false);
  const [isKYCStatusModalOpen, setIsKYCStatusModalOpen] = useState(false);
  const [isSendEmailModalOpen, setIsSendEmailModalOpen] = useState(false);

  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('first_name');
  const [selectedStatus, setSelectedStatus] = useState('');

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  const handleStatusChange = (event) => {
    setSelectedStatus(event.target.value);
  };

  const getKycStatusDescription = (kyc_status_id) => {
    const status = kyc_statuses.find(status => status.kyc_status_id === kyc_status_id);
    if (!status) return 'Unknown';
    if (status.kyc_status_description.includes("Sumsub is reviewing the application") && status.status_action_sumsub) {
      return `${status.kyc_status_description} (${status.status_action_sumsub})`;
    }
    return status.kyc_status_description;
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const filteredData = filteredUsers.filter(user => {
    const kycStatusDescription = getKycStatusDescription(user.kyc_status_id).toLowerCase();
    const matchesSearch = (
      (user.first_name && user.first_name.toLowerCase().includes(searchTerm)) ||
      (user.last_name && user.last_name.toLowerCase().includes(searchTerm)) ||
      (user.email && user.email.toLowerCase().includes(searchTerm)) ||
      (user.customer_id && user.customer_id.toLowerCase().includes(searchTerm)) ||
      (kycStatusDescription.includes(searchTerm))
    );
    const matchesStatus = selectedStatus === '' || getKycStatusDescription(user.kyc_status_id) === selectedStatus;
    return matchesSearch && matchesStatus;
  });

  const sortedData = filteredData.sort((a, b) => {
    if (orderBy === 'first_name') {
      return order === 'asc'
        ? (a.first_name || '').localeCompare(b.first_name || '')
        : (b.first_name || '').localeCompare(a.first_name || '');
    }
    if (orderBy === 'last_name') {
      return order === 'asc'
        ? (a.last_name || '').localeCompare(b.last_name || '')
        : (b.last_name || '').localeCompare(a.last_name || '');
    }
    if (orderBy === 'email') {
      return order === 'asc'
        ? (a.email || '').localeCompare(b.email || '')
        : (b.email || '').localeCompare(a.email || '');
    }
    if (orderBy === 'customer_id') {
      return order === 'asc'
        ? (a.customer_id || '').localeCompare(b.customer_id || '')
        : (b.customer_id || '').localeCompare(a.customer_id || '');
    }
    if (orderBy === 'kyc_status') {
      return order === 'asc'
        ? getKycStatusDescription(a.kyc_status_id).localeCompare(getKycStatusDescription(b.kyc_status_id))
        : getKycStatusDescription(b.kyc_status_id).localeCompare(getKycStatusDescription(a.kyc_status_id));
    }
    return 0;
  });

  useEffect(() => {
    const fetchUsers = async () => {
        setLoading(true);
        const response = await get_basic_user_list();
        if (response && response.status === 'success') {
            const users = Object.values(response.users).map(user => ({
                ...user,
                first_name: user.first_name || 'N/A',
                last_name: user.last_name || 'N/A'
            }));
            setFilteredUsers(users);
        }
        setLoading(false);
    };
    fetchUsers();
}, []);

  const handleRowClick = (user) => {
    setSelectedUser(user);
    setIsCustomerOptionsModalOpen(true);
  };

  const handleCustomerOptionsClose = () => {
    setIsCustomerOptionsModalOpen(false);
    setSelectedUser(null);
  };

  const handleOptionSelect = (option) => {
    setIsCustomerOptionsModalOpen(false);

    switch (option) {
      case 'details':
        setIsCustomerDetailModalOpen(true);
        break;
      case 'accounts':
        setIsCustomerAccountsModalOpen(true);
        break;
      case 'transactions':
        setIsCustomerTransactionsModalOpen(true);
        break;
      case 'cards':
        setIsCustomerCardsModalOpen(true);
        break;
      {/*case 'card_transactions':
        setIsCardTransactionsModalOpen(true);
        break;*/}
      case 'kyc_status':
        setIsKYCStatusModalOpen(true);
        break;
      case 'send_email':
        setIsSendEmailModalOpen(true);
        break;
      case 'change_email':
        setIsUserDetailsModalOpen(true);
        break;
      default:
        break;
    }
  };

  const handleUserDetailsModalClose = () => {
    setIsUserDetailsModalOpen(false);
  };

  const handleSave = async (newEmail) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(newEmail)) {
      return 'Invalid email format';
    }

    const emailExists = filteredUsers.some(user => user.email === newEmail);
    if (emailExists) {
      return 'The email is already in use';
    }

    const updateResponse = await update_user_email(selectedUser.email, newEmail);
    if (updateResponse.status === 'success') {
      setFilteredUsers(filteredUsers.map(user =>
        user.customer_id === selectedUser.customer_id ? { ...user, email: newEmail } : user
      ));
      setIsUserDetailsModalOpen(false);
      return 'success';
    } else {
      return 'Error updating email: ' + updateResponse.message;
    }
  };

  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <div style={{ marginLeft: 'auto', marginRight: 'auto', maxWidth: '1500px' }}>
      <ThemeProvider theme={theme}>
        <Grid container spacing={2} style={{ marginTop: '20px', marginBottom: '20px' }}>
          <Grid item xs={6}>
            <TextField
              label="Search"
              variant="outlined"
              value={searchTerm}
              onChange={handleSearchChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>KYC Status</InputLabel>
              <Select
                value={selectedStatus}
                onChange={handleStatusChange}
                label="KYC Status"
              >
                <MenuItem value="">All</MenuItem>
                {kyc_statuses.map((status) => (
                  <MenuItem key={status.kyc_status_id} value={getKycStatusDescription(status.kyc_status_id)}>
                    {getKycStatusDescription(status.kyc_status_id)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Table style={{ marginTop: '20px' }}>
          <TableHead>
            <TableRow>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'first_name'}
                  direction={orderBy === 'first_name' ? order : 'asc'}
                  onClick={(event) => handleRequestSort(event, 'first_name')}
                >
                  First Name
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'last_name'}
                  direction={orderBy === 'last_name' ? order : 'asc'}
                  onClick={(event) => handleRequestSort(event, 'last_name')}
                >
                  Last Name
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'email'}
                  direction={orderBy === 'email' ? order : 'asc'}
                  onClick={(event) => handleRequestSort(event, 'email')}
                >
                  Email
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'customer_id'}
                  direction={orderBy === 'customer_id' ? order : 'asc'}
                  onClick={(event) => handleRequestSort(event, 'customer_id')}
                >
                  Customer ID
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'kyc_status'}
                  direction={orderBy === 'kyc_status' ? order : 'asc'}
                  onClick={(event) => handleRequestSort(event, 'kyc_status')}
                >
                  KYC Status
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((user, index) => (
              <TableRow key={index} onClick={() => handleRowClick(user)} style={{ cursor: 'pointer' }}>
                <TableCell>{user.first_name}</TableCell>
                <TableCell>{user.last_name}</TableCell>
                <TableCell>{user.email || 'N/A'}</TableCell>
                <TableCell>{user.customer_id || 'N/A'}</TableCell>
                <TableCell>{getKycStatusDescription(user.kyc_status_id)}</TableCell>
              </TableRow>
            ))}
          </TableBody>

        </Table>
        <TablePagination
          component="div"
          count={sortedData.length}
          page={page}
          onPageChange={(event, newPage) => setPage(newPage)}
          rowsPerPage={rowsPerPage}
          labelRowsPerPage="Rows per page"
          onRowsPerPageChange={(event) => {
            setRowsPerPage(parseInt(event.target.value, 10));
            setPage(0);
          }}
        />
        {selectedUser && (
          <Modal_CustomerOptions
            isOpen={isCustomerOptionsModalOpen}
            onClose={handleCustomerOptionsClose}
            user={selectedUser}
            onOptionSelect={handleOptionSelect}
          />
        )}
        {selectedUser && isUserDetailsModalOpen && (
          <Modal_UserDetails
            isOpen={isUserDetailsModalOpen}
            onClose={handleUserDetailsModalClose}
            user={selectedUser}
            onSave={handleSave}
          />
        )}
        {selectedUser && isCustomerDetailModalOpen && (
          <Modal_CustomerDetail
            isOpen={isCustomerDetailModalOpen}
            onClose={() => setIsCustomerDetailModalOpen(false)}
            customer_id={selectedUser.customer_id}
          />
        )}
        {selectedUser && isCustomerAccountsModalOpen && (
          <Modal_CustomerAccounts
            isOpen={isCustomerAccountsModalOpen}
            onClose={() => setIsCustomerAccountsModalOpen(false)}
            customer_id={selectedUser.customer_id}
          />
        )}
        {selectedUser && isCustomerTransactionsModalOpen && (
          <Modal_CustomerTransactions
            isOpen={isCustomerTransactionsModalOpen}
            onClose={() => setIsCustomerTransactionsModalOpen(false)}
            userEmail={selectedUser.customer_id}
          />
        )}
        {selectedUser && isCustomerCardsModalOpen && (
          <Modal_CustomerCards
            isOpen={isCustomerCardsModalOpen}
            onClose={() => setIsCustomerCardsModalOpen(false)}
            customer_id={selectedUser.customer_id}
          />
        )}
        {selectedUser && isCardTransactionsModalOpen && (
          <Modal_CardTransactions
            isOpen={isCardTransactionsModalOpen}
            onClose={() => setIsCardTransactionsModalOpen(false)}
            userEmail={selectedUser.customer_id}
          />
        )}
        {selectedUser && isKYCStatusModalOpen && (
          <Modal_KYCStatus
            isOpen={isKYCStatusModalOpen}
            onClose={() => setIsKYCStatusModalOpen(false)}
            userEmail={selectedUser.email}
          />
        )}
        {selectedUser && isSendEmailModalOpen && (
          <Modal_SendEmail
            isOpen={isSendEmailModalOpen}
            onClose={() => setIsSendEmailModalOpen(false)}
            userEmail={selectedUser.email}
          />
        )}
        {selectedUser && (
          <Modal_CustomerOptions
            isOpen={isCustomerOptionsModalOpen}
            onClose={handleCustomerOptionsClose}
            user={selectedUser}
            onOptionSelect={handleOptionSelect}
            allEmails={filteredUsers.map(user => user.email)} // Pass all emails
          />
        )}

      </ThemeProvider>
    </div>
  );
}

export default Page_Customers;
