import React, { useState, useEffect } from 'react';
import { theme } from './ReactStyles';
import { Grid, Card, CardContent, Typography, Button, Box, Table, TableBody, TableCell, TextField, TableHead, TableRow, TableSortLabel, Select, MenuItem, FormControl, InputLabel, ListItemIcon, ListItemText, ThemeProvider } from '@mui/material';
import { t, number_to_italian_currency } from './Util_format';
import { useNavigate } from 'react-router-dom';
import { format, parseISO, isWithinInterval } from 'date-fns';
import TablePagination from '@mui/material/TablePagination';
import { get_fee_values } from './Util_API_calls';
import { CircularProgress } from '@mui/material';
import kyc_statuses from './config/kyc_statuses.json';

function Page_FeeValues({ onDataChange, ...props }) {
  const [feeValues, setFeeValues] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchFeeValues = async () => {
      try {
        const response = await get_fee_values();
        if (response && response.status === 'success') {
          setFeeValues(response.data);
        } else {
          console.error('Failed to fetch fee values:', response);
        }
      } catch (error) {
        console.error('Error fetching fee values:', error);
      }
      setLoading(false);
    };

    fetchFeeValues();
  }, []);

  const getValidFeeValue = (feeType, date) => {
    if (!feeValues || !feeValues[feeType]) {
      return null;
    }

    const validFeeValue = feeValues[feeType].find(fee => {
      const validFrom = parseISO(fee.valid_from);
      const validTo = fee.valid_to ? parseISO(fee.valid_to) : null;

      if (validTo) {
        return isWithinInterval(parseISO(date), { start: validFrom, end: validTo });
      } else {
        return parseISO(date) >= validFrom;
      }
    });

    return validFeeValue || null;
  };

  const calculateCardBalanceEnquiryFees = (dailyTransactions) => {
    return dailyTransactions.map(transaction => {
      const { date, login_quantity } = transaction;
      const feeValue = getValidFeeValue('card_balance_enquiry_fee', date);

      if (!feeValue) {
        return { date, fee: null };
      }

      const { ul_fixed_component, crh_fixed_component, customer_fixed_component } = feeValue;
      const fee = (customer_fixed_component - (ul_fixed_component + crh_fixed_component)) * login_quantity;
      return { date, fee };
    });
  };

  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </div>
    );
  }

  const cardBalanceEnquiryFees = feeValues ? calculateCardBalanceEnquiryFees(props.dailyTransactions || []) : [];

  return (
    <div style={{ marginLeft: 'auto', marginRight: 'auto', marginTop: '30px' }}>
      <ThemeProvider theme={theme}>
        <Typography variant="h4" component="h1" gutterBottom>
          Fee Values
        </Typography>
        {/* Display the calculated fees */}
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>Card Balance Enquiry Fee</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {cardBalanceEnquiryFees.map(({ date, fee }) => (
              <TableRow key={date}>
                <TableCell>{date}</TableCell>
                <TableCell>{fee !== null ? fee : 'N/A'}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </ThemeProvider>
    </div>
  );
}

export default Page_FeeValues;