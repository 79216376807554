import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { AppBar, Drawer, Icon, IconButton, List, ListItem, ListItemIcon, ListItemText, Toolbar, Typography, Box, ThemeProvider, styled, Menu, MenuItem } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import HomeIcon from '@mui/icons-material/Home';
import BarChartIcon from '@mui/icons-material/BarChart';
import TableViewIcon from '@mui/icons-material/TableView';
import PersonIcon from '@mui/icons-material/Person';
import AccountCircle from '@mui/icons-material/AccountCircle';
import SearchIcon from '@mui/icons-material/Search';
import { getCookieValue, changeLanguage } from './App';
import logo_loggedpng from './pics/logo_logged.png';
import flag_it from './pics/flag_it.svg';
import flag_en from './pics/flag_en.svg';
import useMediaQuery from '@mui/material/useMediaQuery';
import MenuIcon from '@mui/icons-material/Menu';
import LogoutIcon from '@mui/icons-material/Logout';
import Badge from '@mui/material/Badge';
import NotificationsIcon from '@mui/icons-material/Notifications';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import MailIcon from '@mui/icons-material/Mail';
import { t } from './Util_format';
import MailOutlineIcon from '@mui/icons-material/MailOutline'; // Icon for unread mail
import { markMessageAsRead, fetchPageDashboard } from './Util_API_calls'; // Adjust the path as necessary
import config from './config/env.json';
import { theme } from './ReactStyles';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import MemoryIcon from '@mui/icons-material/Memory';
import BuildIcon from '@mui/icons-material/Build';
import LinkIcon from '@mui/icons-material/Link';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import ViewListIcon from '@mui/icons-material/ViewList';
import TableChartIcon from '@mui/icons-material/TableChart'; // Import the icon for Page_DailyOutputs
import BusinessIcon from '@mui/icons-material/Business';

function Structure_logged({ children, APIPageData = {} }) {
  const location = useLocation();
  const navigate = useNavigate();
  const [drawerReduced, setDrawerReduced] = useState(false);
  const [open, setOpen] = useState(false);
  const [langMenuAnchorEl, setLangMenuAnchorEl] = useState(null);
  const [accountMenuAnchorEl, setAccountMenuAnchorEl] = useState(null);
  const currentFlag = getCookieValue('language') === 'en' ? flag_en : flag_it;
  const [userRole, setUserRole] = useState(''); // Add state for user role


  useEffect(() => {
    const fetchUserRole = async () => {
      const result = await fetchPageDashboard();
      setUserRole(result.role);
      
      if (result.role === 'basic_viewer' && location.pathname !== '/dashboard' && location.pathname !== '/Fornitori') {
        navigate('/dashboard');
      } else if (result.role === 'call_centre' && 
                 !['/customers', '/infrastructure-status', '/Fornitori'].includes(location.pathname)) {
        navigate('/customers');
      }
    };
  
    fetchUserRole();
  }, [navigate, location.pathname]);

  // Use useEffect to update the email state when APIPageData.details.email is available
  const [user_name_surname, setuser_name_surname] = useState('');
  const [user_email, setuser_email] = useState('');

  useEffect(() => {
    if (APIPageData && APIPageData.details) {
      if (APIPageData.details.first_name && APIPageData.details.last_name) {
        setuser_name_surname(APIPageData.details.first_name + ' ' + APIPageData.details.last_name);
      }
      if (APIPageData.details.email) {
        setuser_email(APIPageData.details.email);
      }
    }
  }, [APIPageData]);

  const handleLanguageChange = (lang) => {
    handleClose(); // Close the menu
    changeLanguage(lang); // Change the language
  };

  const handleCloseMessageModal = () => {
    setIsMessagesModalOpen(false);
  };

  const [unreadMessagesCount, setUnreadMessagesCount] = useState(0);

  useEffect(() => {
    // This will safely handle the case when APIPageData is null or undefined
    const count = APIPageData?.messages
      ? APIPageData.messages.filter(msg => !msg.is_read).length
      : 0;

    setUnreadMessagesCount(count);
  }, [APIPageData]); // Dep

  const ListItemTextCickable = styled(ListItemText)(({ theme }) => ({
    '& .MuiListItemText-primary': {
      fontWeight: '500',
      color: '#637381',
      fontSize: '0.875rem',
      fontFamily: 'Poppins',
      textOverflow: 'ellipsis',
    },
    '&.reduced-text .MuiListItemText-primary': { // When reduced
      fontSize: '0.7rem', // Smaller font
      textAlign: 'center', // Corrected here
    },
  }));

  const handleLangMenuClick = (event) => {
    setLangMenuAnchorEl(event.currentTarget);
  };

  const handleAccountMenuClick = (event) => {
    setAccountMenuAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setLangMenuAnchorEl(null);
    setAccountMenuAnchorEl(null);
  };

  const handleLogout = () => {
    navigate('/login?action=clean-cookie&reason=user_logout');
  };

  const handleGoToCustomers = () => {
    navigate('/customers');
    setLangMenuAnchorEl(null);
    setAccountMenuAnchorEl(null);
  };

  const handleGoToHome = () => {
    navigate('/dashboard');
    setLangMenuAnchorEl(null);
    setAccountMenuAnchorEl(null);
  };

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setIsDrawerOpen(open);
  };

  const ListItemIconStyled = styled(ListItemIcon)(({ theme }) => ({
    alignContent: 'center',  // Center the icon
    minWidth: drawerReduced ? 'unset' : undefined,  // Remove the default minWidth only when reduced
  }));

  const StyledListItem = styled(ListItem)(({ theme }) => ({
    cursor: 'pointer',
    borderRadius: '5px',
    marginBottom: '5px',
    transition: 'background-color 0.3s ease',
    flexDirection: drawerReduced ? 'column' : 'row',  // Vertical stack when reduced
    justifyContent: drawerReduced ? 'center' : 'flex-start',  // Centered when reduced
    alignItems: 'center',  // Horizontally center
    '&:hover': {
      backgroundColor: '#edefef',
    },
    '&:active': {
      backgroundColor: '#c5cacf',
    },
  }));

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  const TitleListItem = styled(ListItem)(({ theme }) => ({
    pointerEvents: 'none',
    userSelect: 'none',
    fontSize: '0.8rem',
    paddingBottom: '10px',
  }));

  const isMobile = useMediaQuery('(max-width:768px)'); // Mobile breakpoint

  const contentStyles = isMobile
    ? {
      marginLeft: 0,
      width: 'calc(100vw - 40px)',
      paddingLeft: '20px',
      paddingRight: '20px',
      height: 'calc(100vh - 80px)',
      overflowY: 'auto',
      justifyContent: 'center !important',
      textAlign: 'center !important',
    }
    : {
      marginLeft: drawerReduced ? 100 : 260,
      width: drawerReduced ? 'calc(100vw - 140px)' : 'calc(100vw - 300px)',
      height: 'calc(100vh - 80px)',
      overflowY: 'auto', // fixed property name
      justifyContent: 'center',
      alignItems: 'center',
      color: '#FFFFFF',
      paddingTop: '0px',
      paddingLeft: '20px',
      paddingRight: '20px'
    };

  // if the cookie is not present, redirect to the login page
  useEffect(() => {
    const cookieRow = document.cookie.split('; ').find(row => row.startsWith('firebaseToken='));
    const cookieValue = cookieRow ? cookieRow.split('=')[1] : null;

    if (!cookieValue) {
      navigate('/login');
    } else {
      const loginOtpCompleted = getCookieValue('login_otp_completed');
      if (loginOtpCompleted !== 'true') {
        navigate("/login_otp");
      }
    }
  }, []);

  const [isMessagesModalOpen, setIsMessagesModalOpen] = useState(false);
  const [currentMessage, setCurrentMessage] = useState({ msg_id: null, subject: '', content: '' });

  // Function to open the modal
  const openMessagesModal = () => {
    setIsMessagesModalOpen(true);
  };

  // Function to close the modal
  const closeMessagesModal = () => {
    setIsMessagesModalOpen(false);
  };

  const handleReadMessage = (message) => {
    markMessageAsRead(message.msg_id);

    const updatedMessages = APIPageData.messages.map(msg =>
      msg.msg_id === message.msg_id ? { ...msg, is_read: true } : msg
    );

    setCurrentMessage({
      msg_id: message.msg_id, // Assuming the message object has an 'id' field
      subject: message.subject,
      content: message.content
    });

    const newUnreadCount = updatedMessages.filter(msg => !msg.is_read).length;
    setUnreadMessagesCount(newUnreadCount);

    openMessagesModal(); // Open the modal
  };

  const handleLogoClick = () => {
    if (userRole === 'call_centre') {
      navigate('/customers');
    } else {
      navigate('/dashboard');
    }
  };


  return (
    <div id="page_dashboard" className="page_dashboard">
      <ThemeProvider theme={theme}>
        {isMobile && (
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleDrawerToggle}
            style={{
              position: 'absolute',
              backdropFilter: 'blur(10px)',
              top: '18px',
              left: '20px',
              zIndex: 1201
            }}
          >
            <MenuIcon />
          </IconButton>

        )}
        {!isMobile && (
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => setDrawerReduced(!drawerReduced)}
            style={{
              position: 'absolute',
              top: '40px',
              transform: 'translateY(-50%) scale(0.5)',
              left: drawerReduced ? '90px' : '250px',
              border: '1px dotted #AAAAAA',
              borderRadius: '50%',
              width: '48px !important',
              height: '48px !important',
              zIndex: 1202,
              backgroundColor: '#FEFEFE',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {drawerReduced ?
              <ArrowForwardIcon style={{ fontSize: '24px' }} /> : <ArrowBackIcon style={{ fontSize: '24px' }} />
            }
          </IconButton>
        )}
        <AppBar position="static" style={{
          marginLeft: isMobile ? 0 : (drawerReduced ? 100 : 260),
          width: isMobile ? 'calc(100vw)' : (drawerReduced ? 'calc(100vw - 100px)' : 'calc(100vw - 260px)'),
          paddingLeft: isMobile ? 60 : (drawerReduced ? 0 : 0)
        }}>
          <Toolbar style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            height: '80px',
          }}
          >
            <IconButton
              style={{ visibility: 'hidden' }} // Hide the button
            >
              <SearchIcon />
            </IconButton>

            <div style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>

<Typography
  variant="h6"
  style={{
    marginRight: '5rem',
    color: 'black',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  }}
>
  {(() => {
    switch (userRole) {
      case 'basic_viewer':
        return 'Sola Lettura';
      case 'call_centre':
        return 'Helpdesk User';
      case 'super_admin':
        return 'Super Admin';
      default:
        return 'Loading....';
    }
  })()}
</Typography>



      
              {/* Language Menu */}
              <IconButton onClick={handleLangMenuClick}>
                <img src={currentFlag} alt="Italiano" style={{ width: '28px', height: '28px', borderRadius: '10px' }} />
              </IconButton>
              <Menu
                anchorEl={langMenuAnchorEl}
                keepMounted
                open={Boolean(langMenuAnchorEl)}
                onClose={handleClose}
                PaperProps={{
                  style: {
                    borderRadius: 8,
                    marginTop: '10px'
                  },
                }}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
              >
                {[
                  { flag: flag_it, alt: "Italiano", lang: "it" },
                  { flag: flag_en, alt: "English", lang: "en" }
                ].map(item => (
                  <MenuItem
                    key={item.lang}
                    onClick={() => handleLanguageChange(item.lang)}
                    style={{
                      fontWeight: '500',
                      color: '#637381',
                      fontSize: '0.875rem',
                      fontFamily: 'Poppins',
                      textOverflow: 'ellipsis'
                    }}
                  >
                    <img src={item.flag} alt={item.alt} style={{ width: '28px', height: '20px', marginRight: '10px' }} />
                    {item.alt}
                  </MenuItem>
                ))}
              </Menu>

              {/* Notifications */}
              <IconButton onClick={toggleDrawer(true)}>
                <Badge badgeContent={unreadMessagesCount} color="error">
                  <NotificationsIcon />
                </Badge>
              </IconButton>
              <Drawer anchor="right" open={isDrawerOpen} onClose={toggleDrawer(false)}>
                <div
                  role="presentation"
                  onClick={toggleDrawer(false)}
                  onKeyDown={toggleDrawer(false)}
                >
                  <div style={{ padding: '20px' }}>
                    <Typography className='notifications_title'>
                      {t('notifications')}
                    </Typography>
                    {
                      APIPageData?.messages && APIPageData.messages.length > 0 ? (
                        APIPageData.messages.map((message, index) => {
                          // Log the entire message object
                          // console.log('Message:', message);

                          return (
                            <div
                              className="notifications_list_with_border"
                              key={index}
                              onClick={() => handleReadMessage(message)}
                              style={{ display: 'flex', alignItems: 'center' }}
                            >
                              <Icon style={{ color: message.is_read ? '#f0f0f0' : '#339AD8', marginRight: '8px' }}>
                                {message.is_read ? <MailIcon /> : <MailOutlineIcon />}
                              </Icon>
                              {message.subject} e {message.is_read.toString()}
                            </div>
                          );
                        })
                      ) : (
                        <Typography className='no_notifications'>
                          {t('no_notifications')}
                        </Typography>
                      )
                    }
                  </div>

                </div>
              </Drawer>
              {/* Account Menu */}
              <IconButton onClick={handleAccountMenuClick}>
                <AccountCircleIcon style={{ width: '28px', height: '28px', borderRadius: '5px' }} color="primary" />
              </IconButton>
              <Menu
                anchorEl={accountMenuAnchorEl}
                keepMounted
                open={Boolean(accountMenuAnchorEl)}
                onClose={handleClose}
                PaperProps={{
                  style: {
                    borderRadius: 8,
                    marginTop: '10px'
                  },
                }}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
              >
                <MenuItem onClick={handleGoToHome} style={{
                  fontWeight: '500',
                  color: '#637381',
                  fontSize: '0.875rem',
                  fontFamily: 'Poppins',
                  textOverflow: 'ellipsis',
                }}
                >
                  <HomeIcon color="primary" style={{ paddingRight: '10px' }} />
                  {t("dashboard")}
                </MenuItem>

                <MenuItem onClick={handleLogout}
                  style={{
                    fontWeight: '500',
                    color: '#637381',
                    fontSize: '0.875rem',
                    fontFamily: 'Poppins',
                    textOverflow: 'ellipsis'
                  }}
                >
                  <LogoutIcon color="primary" style={{ paddingRight: '10px' }} />
                  {t("logout")}
                </MenuItem>
              </Menu>
            </div>
          </Toolbar>

        </AppBar>
        <div style={{ ...contentStyles }}>
          {children}
        </div>

        <Drawer
          variant={isMobile ? "temporary" : "permanent"}
          open={isMobile ? open : true}
          onClose={handleDrawerToggle}
          className={drawerReduced ? "reduced-drawer" : ""}
          classes={{
            paper: drawerReduced ? "reduced-drawer" : "",
          }}
          style={{ padding: '5px' }}
        >
          <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <div role="presentation" onClick={toggleDrawer} onKeyDown={toggleDrawer}>

              <div role="presentation" onClick={toggleDrawer} onKeyDown={toggleDrawer}>
                <List>
                  <ListItem
                    style={{
                      justifyContent: 'center',
                      paddingTop: '20px',
                      paddingBottom: '20px',
                      userSelect: 'none',
                      pointerEvents: 'auto', // Enable pointer events
                      backgroundColor: 'transparent', // Set default background color
                      '&:hover': {
                        backgroundColor: 'transparent' // Keep background color transparent on hover
                      }
                    }}
                  >
                    <img
                      src={logo_loggedpng}
                      alt="Company Logo"
                      style={{
                        width: '80px',
                        height: '80px',
                        cursor: 'pointer' // Ensure cursor changes to pointer on hover
                      }}
                      onClick={handleLogoClick} // Attach the click handler
                    />
                  </ListItem>

                  <TitleListItem>
                    {!drawerReduced && (
                      <ListItemText
                        primary="PANNELLO DI AMMINISTRAZIONE"
                        primaryTypographyProps={{ style: { fontWeight: 'bold', color: '#339AD8', fontSize: '11px' } }}
                      />
                    )}
                  </TitleListItem>
                  
{/* Render dashboard for all roles except call_centre */}
{userRole !== 'call_centre' && (
  <StyledListItem
    onClick={() => { navigate('/dashboard'); }}
    style={location.pathname.startsWith('/dashboard') ? { backgroundColor: '#e0f0f9' } : {}}
  >
    <ListItemIconStyled><HomeIcon color="primary" /></ListItemIconStyled>
    {!drawerReduced ? (
      <ListItemTextCickable primary={t("dashboard")} />
    ) : (
      <ListItemTextCickable className="reduced-text" primary={t("dashboard")} />
    )}
  </StyledListItem>
)}

{/* Render Fornitori for all roles */}
<StyledListItem
  onClick={() => { navigate('/Fornitori'); }}
  style={location.pathname.startsWith('/Fornitori') ? { backgroundColor: '#e0f0f9' } : {}}
>
  <ListItemIconStyled><BusinessIcon color="primary" /></ListItemIconStyled>
  {!drawerReduced ? (
    <ListItemTextCickable primary={t("Fornitori")} />
  ) : (
    <ListItemTextCickable className="reduced-text" primary={t("Fornitori")} />
  )}
</StyledListItem>


{/* Render customers page for call_centre and super_admin */}
{(userRole === 'super_admin' || userRole === 'call_centre') && (
  <StyledListItem
    onClick={() => { navigate('/customers'); }}
    style={location.pathname.startsWith('/customers') ? { backgroundColor: '#e0f0f9' } : {}}
  >
    <ListItemIconStyled><PersonIcon color="primary" /></ListItemIconStyled>
    {!drawerReduced ? (
      <ListItemTextCickable primary={t("customers")} />
    ) : (
      <ListItemTextCickable className="reduced-text" primary={t("customers")} />
    )}
  </StyledListItem>
)}

  <TitleListItem>
  {!drawerReduced && (userRole === 'super_admin' || userRole === 'call_centre') && (
    <ListItemText
      primary={t('computer_systems')}
      primaryTypographyProps={{ style: { fontWeight: 'bold', color: '#339AD8', fontSize: '11px' } }}
    />
  )}
</TitleListItem>

{(userRole === 'super_admin' || userRole === 'call_centre') && (
  <StyledListItem
    onClick={() => { navigate('/infrastructure-status'); }}
    style={location.pathname.startsWith('/infrastructure-status') ? { backgroundColor: '#e0f0f9' } : {}}
  >
    <ListItemIconStyled><MemoryIcon color="primary" /></ListItemIconStyled>
    {!drawerReduced ? (
      <ListItemTextCickable primary={t("infrastructure-status")} />
    ) : (
      <ListItemTextCickable className="reduced-text" primary={t("infrastructure-status")} />
    )}
  </StyledListItem>
)}




{/* Render all other menu items only for super_admin */}
{userRole === 'super_admin' && (
  <>
    <StyledListItem
      onClick={() => { navigate('/super-admin-operations'); }}
      style={location.pathname.startsWith('/super-admin-operations') ? { backgroundColor: '#e0f0f9' } : {}}
    >
      <ListItemIconStyled><BuildIcon color="primary" /></ListItemIconStyled>
      {!drawerReduced ? (
        <ListItemTextCickable primary={t("Operazioni Superadmin")} />
      ) : (
        <ListItemTextCickable className="reduced-text" primary={t("super-admin-operations")} />
      )}
    </StyledListItem>
                 

                      <TitleListItem>
                        {!drawerReduced && (
                          <ListItemText
                            primary={t('financials')}
                            primaryTypographyProps={{ style: { fontWeight: 'bold', color: '#339AD8', fontSize: '11px' } }}
                          />
                        )}
                      </TitleListItem>

                      <StyledListItem
                        onClick={() => { navigate('/business-volume'); }}
                        style={location.pathname.startsWith('/business-volume') ? { backgroundColor: '#e0f0f9' } : {}}
                      >
                        <ListItemIconStyled><TrendingUpIcon color="primary" /></ListItemIconStyled>
                        {!drawerReduced ? (
                          <ListItemTextCickable primary={t("business-volume")} />
                        ) : (
                          <ListItemTextCickable className="reduced-text" primary={t("business-volume")} />
                        )}
                      </StyledListItem>

                      <StyledListItem
                        onClick={() => { navigate('/daily-outputs'); }}
                        style={location.pathname.startsWith('/daily-outputs') ? { backgroundColor: '#e0f0f9' } : {}}
                      >
                        <ListItemIconStyled><TableChartIcon color="primary" /></ListItemIconStyled>
                        {!drawerReduced ? (
                          <ListItemTextCickable primary="Daily Outputs" />
                        ) : (
                          <ListItemTextCickable className="reduced-text" primary="Daily Outputs" />
                        )}
                      </StyledListItem>

                      <StyledListItem
                        onClick={() => { navigate('/pl'); }}
                        style={location.pathname.startsWith('/pl') ? { backgroundColor: '#e0f0f9' } : {}}
                      >
                        <ListItemIconStyled><TableViewIcon color="primary" /></ListItemIconStyled>
                        {!drawerReduced ? (
                          <ListItemTextCickable primary={t("PL")} />
                        ) : (
                          <ListItemTextCickable className="reduced-text" primary={t("PL")} />
                        )}
                      </StyledListItem>

                      <StyledListItem
                        onClick={() => { navigate('/charts'); }}
                        style={location.pathname.startsWith('/charts') ? { backgroundColor: '#e0f0f9' } : {}}
                      >
                        <ListItemIconStyled><BarChartIcon color="primary" /></ListItemIconStyled>
                        {!drawerReduced ? (
                          <ListItemTextCickable primary={t("charts")} />
                        ) : (
                          <ListItemTextCickable className="reduced-text" primary={t("charts")} />
                        )}
                      </StyledListItem>

                      <StyledListItem
                        onClick={() => { navigate('/master-account'); }}
                        style={location.pathname.startsWith('/master-account') ? { backgroundColor: '#e0f0f9' } : {}}
                      >
                        <ListItemIconStyled><AccountBalanceIcon color="primary" /></ListItemIconStyled>
                        {!drawerReduced ? (
                          <ListItemTextCickable primary={t("master-account")} />
                        ) : (
                          <ListItemTextCickable className="reduced-text" primary={t("master-account")} />
                        )}
                      </StyledListItem>
                     
                      <StyledListItem
                      onClick={() => { navigate('/maintenance'); }}
                      style={location.pathname.startsWith('/maintenance') ? { backgroundColor: '#e0f0f9' } : {}}
                     >
                     <ListItemIconStyled><BuildIcon color="primary" /></ListItemIconStyled>
                     {!drawerReduced ? (
                     <ListItemTextCickable primary={t("Manutenzione")} />
                     ) : (
                    <ListItemTextCickable className="reduced-text" primary={t("Manutenzione")} />
                     )}
                    </StyledListItem>


                      <TitleListItem>
                        {!drawerReduced && (
                          <ListItemText
                            primary={t('BANKING')}
                            primaryTypographyProps={{ style: { fontWeight: 'bold', color: '#339AD8', fontSize: '11px' } }}
                          />
                        )}
                      </TitleListItem>

                      <StyledListItem
                        onClick={() => { navigate('/kyc-status'); }}
                        style={location.pathname.startsWith('/kyc-status') ? { backgroundColor: '#e0f0f9' } : {}}
                      >
                        <ListItemIconStyled><VerifiedUserIcon color="primary" /></ListItemIconStyled>
                        {!drawerReduced ? (
                          <ListItemTextCickable primary={t("kyc-status")} />
                        ) : (
                          <ListItemTextCickable className="reduced-text" primary={t("kyc-status")} />
                        )}
                      </StyledListItem>

                      <TitleListItem>
                        {!drawerReduced && (
                          <ListItemText
                            primary={t('computer_systems')}
                            primaryTypographyProps={{ style: { fontWeight: 'bold', color: '#339AD8', fontSize: '11px' } }}
                          />
                        )}
                      </TitleListItem>



                      <StyledListItem
                        onClick={() => { navigate('/unlimit'); }}
                        style={location.pathname.startsWith('/unlimit') ? { backgroundColor: '#e0f0f9' } : {}}
                      >
                        <ListItemIconStyled><LinkIcon color="primary" /></ListItemIconStyled>
                        {!drawerReduced ? (
                          <ListItemTextCickable primary={t("unlimit")} />
                        ) : (
                          <ListItemTextCickable className="reduced-text" primary={t("unlimit")} />
                        )}
                      </StyledListItem>

                      <StyledListItem
                        onClick={() => { navigate('/epay'); }}
                        style={location.pathname.startsWith('/epay') ? { backgroundColor: '#e0f0f9' } : {}}
                      >
                        <ListItemIconStyled><ViewListIcon color="primary" /></ListItemIconStyled>
                        {!drawerReduced ? (
                          <ListItemTextCickable primary={t("epay")} />
                        ) : (
                          <ListItemTextCickable className="reduced-text" primary={t("epay")} />
                        )}
                      </StyledListItem>
                    </>
                  )}

                </List>
              </div>
            </div>
          </div>
        </Drawer>
      </ThemeProvider>        
    </div>
  );
}

export default Structure_logged;
