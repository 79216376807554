// UnrecognizedTransactionsModal.js
import React from 'react';
import { Modal, Box, Typography, Table, TableBody, TableCell, TableContainer, TableRow, Paper } from '@mui/material';

const Modal_UnrecognizedTransactions= ({ open, handleClose, transactions }) => {
  return (
    <Modal open={open} onClose={handleClose} aria-labelledby="modal-title">
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
      }}>
        <Typography id="modal-title" variant="h6" component="h2">
          Transazioni Non Riconosciute
        </Typography>
        {transactions.length > 0 ? (
          <TableContainer component={Paper}>
            <Table size="small">
              <TableBody>
                {transactions.map((transaction, index) => (
                  <TableRow key={index}>
                    <TableCell>{transaction.first_name}</TableCell>
                    <TableCell>{transaction.last_name}</TableCell>
                    <TableCell>{transaction.email}</TableCell>
                    <TableCell>{transaction.phone}</TableCell>
                    <TableCell>{transaction.amount}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Typography>Nessuna transazione da mostrare</Typography>
        )}
      </Box>
    </Modal>
  );
};

export default Modal_UnrecognizedTransactions;