import React, { useEffect, useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, CircularProgress, Typography, Table, TableBody, TableCell, TableHead, TableRow, Menu, MenuItem } from '@mui/material';
import { fetch_customer_bank_accounts } from './Util_API_calls';
import Modal_BlockAccount from './Modal_BlockAccount';

const Modal_CustomerAccounts = ({ isOpen, onClose, customer_id }) => {
  const [accountsData, setAccountsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [isBlockAccountModalOpen, setIsBlockAccountModalOpen] = useState(false);

 
  useEffect(() => {
    const fetchDetails = async () => {
      setLoading(true);
      setErrorMessage('');
      try {
        const result = await fetch_customer_bank_accounts(customer_id);
        if (result.status === 'success') {
          setAccountsData(result.accounts);
        } else {
          if (result.fdt_error_code === 'UNLIMIT_INVALID_BALANCE_ERROR') {
            setErrorMessage("Errore, stiamo investigando la causa, probabilmente l'account è stato chiuso");
          } else {
            setErrorMessage(result.message || 'Non ci sono dettagli al momento');
          }
          if (result.fdt_error_code === 'INSUFFICIENT_ACCESS_RIGHTS') {
            setErrorMessage("Errore, conto sotto investigazione, l'utente non ha la accesso al bilancio del conto perche' e' stato bloccato (possibile frode)");
          } else {
            setErrorMessage(result.message || 'Non ci sono dettagli al momento');
          }
        }
      } catch (error) {
        setErrorMessage('Non ci sono dettagli al momento');
      }
      setLoading(false);
    };
  
    if (isOpen) {
      fetchDetails();
    }
  }, [isOpen, customer_id]);

  const handleClose = () => {
    setAccountsData([]);
    setErrorMessage('');
    onClose();
  };

  const handleOptionsClick = (event, account) => {
    setAnchorEl(event.currentTarget);
    setSelectedAccount(account);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleBlockAccountClick = () => {
    setIsBlockAccountModalOpen(true);
    handleMenuClose();
  };

  const handleBlockAccountModalClose = () => {
    setIsBlockAccountModalOpen(false);
    setSelectedAccount(null);
  };

  return (
    <>
      <Dialog open={isOpen} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle>Conti Cliente</DialogTitle>
        <DialogContent>
          {loading ? (
            <CircularProgress />
          ) : errorMessage ? (
            <Typography color="error">{errorMessage}</Typography>
          ) : accountsData.length === 0 ? (
            <Typography>Non ci sono dettagli al momento</Typography>
          ) : (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Opzioni</TableCell>
                  <TableCell>Numero Conto</TableCell>
                  <TableCell>Bilancio</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {accountsData.map((account, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={(event) => handleOptionsClick(event, account)}
                      >
                        Opzioni
                      </Button>
                    </TableCell>
                    <TableCell>{account.account_number || 'N/A'}</TableCell>
                    <TableCell>{account.balance !== null ? account.balance : 'N/A'}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Chiudi
          </Button>
        </DialogActions>
      </Dialog>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={() => console.log('Transazioni Conto clicked for account:', selectedAccount)}>Transazioni Conto</MenuItem>
        <MenuItem onClick={handleBlockAccountClick}>Blocca Conto</MenuItem>
      </Menu>
      {selectedAccount && (
        <Modal_BlockAccount
          isOpen={isBlockAccountModalOpen}
          onClose={handleBlockAccountModalClose}
          customer_id={customer_id}
          accountId={selectedAccount.account_id}
        />
      )}
    </>
  );
};

export default Modal_CustomerAccounts;