import React, { useState, useEffect } from 'react';
import { ThemeProvider, Typography, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, FormControl, Select, MenuItem, Card, CardContent } from '@mui/material';
import { theme } from './ReactStyles';
import { fetchSystemsHealth } from './Util_API_calls';

function Page_Infrastructurestatus() {
    const [systemsHealth, setSystemsHealth] = useState({});
    const [timeZone, setTimeZone] = useState('Europe/Rome'); // Default display timezone
    const [isLoading, setIsLoading] = useState(false);
    const [rawData, setRawData] = useState(null); // State to hold raw JSON data

    const fetchData = async () => {
        setIsLoading(true);
        const result = await fetchSystemsHealth();
        setRawData(result);
        if (result.status === 'success') {
            const systemsStatus = {};
            const currentTime = new Date();
    
            // Helper function to parse date strings from API
            const parseApiDate = (dateString) => {
                const [datePart, timePart] = dateString.split(' ');
                const [year, month, day] = datePart.split('-');
                const [hour, minute, second] = timePart.split(':');
                return new Date(Date.UTC(year, month - 1, day, hour, minute, second));
            };
    
            // Process global 'all-systems' status
            const allSystemsStatus = result.systems['all-systems'];
            let globalStatus = 'Attivo';
            if (allSystemsStatus) {
                const allSystemsStartTime = parseApiDate(allSystemsStatus.start_time);
                const allSystemsEndTime = parseApiDate(allSystemsStatus.end_time);
                if (currentTime >= allSystemsStartTime && currentTime <= allSystemsEndTime) {
                    globalStatus = `Inattivo fino a ${allSystemsEndTime.toLocaleString('it-IT', { timeZone })}`;
                } else if (currentTime < allSystemsStartTime) {
                    globalStatus = `Manutenzione programmata da ${allSystemsStartTime.toLocaleString('it-IT', { timeZone })} a ${allSystemsEndTime.toLocaleString('it-IT', { timeZone })}`;
                }
            }
    
            const systemMapping = {
                'Epay Phone Recharges': 'epay_phone_recharge',
                'Epay Bollettini': 'epay_bollettini896',
                'Epay PagoPA': 'epay_pagopa',
                'Epay Bollo Auto': 'epay_bolloauto',
                'Epay Mav': 'epay_mav',
                'Epay Rav': 'epay_rav',
                'Epay Vouchers (PIN)': 'epay_vouchers_with_pin',
                'Baas Operations': 'baas-all',
                'Baas Bank Transfers': 'baas-payments',
                'Baas Card/Bankaccount Recharges': 'baas-account-recharges'
            };
    
            Object.entries(systemMapping).forEach(([displayName, systemKey]) => {
                let status = globalStatus; // Default to the global 'all-systems' status
                if (result.systems[systemKey]) {
                    const { start_time, end_time } = result.systems[systemKey];
                    const startTime = parseApiDate(start_time);
                    const endTime = parseApiDate(end_time);
                    if (currentTime >= startTime && currentTime <= endTime) {
                        status = `Inattivo fino a ${endTime.toLocaleString('it-IT', { timeZone })}`;
                    } else if (currentTime < startTime) {
                        status = `Manutenzione programmata da ${startTime.toLocaleString('it-IT', { timeZone })} a ${endTime.toLocaleString('it-IT', { timeZone })}`;
                    }
                }
                systemsStatus[displayName] = status;
            });
    
            setSystemsHealth(systemsStatus);
        }
        setIsLoading(false);
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleRefresh = () => {
        fetchData();
    };

    const handleTimeZoneChange = (event) => {
        setTimeZone(event.target.value);
        fetchData(); // Refresh data with new timezone
    };

    return (
        <div style={{ marginLeft: 'auto', marginRight: 'auto', maxWidth: '1500px' }}>
            <ThemeProvider theme={theme}>
                <Typography variant="h2" color="primary" align="center" gutterBottom sx={{ mt: 4, mb: 2 }}>
                    Stato del Sistema
                </Typography>

                <Typography variant="h4" align="center" gutterBottom sx={{ mt: 2, mb: 4, color:"red" }}>
                    Fusorario selezionato: {timeZone.replace('Europe/', '')}
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 5, mb: 5 }}>
                    <FormControl sx={{ width: 200, mr: 2 }}>
                        <Select value={timeZone} onChange={handleTimeZoneChange}>
                            <MenuItem value="Europe/Rome">Ora Italiana</MenuItem>
                            <MenuItem value="UTC">UTC</MenuItem>
                            <MenuItem value="America/New_York">Eastern Time</MenuItem>
                            <MenuItem value="Asia/Tokyo">Japan Time</MenuItem>
                        </Select>
                    </FormControl>
                    <Button variant="contained" color="primary" onClick={handleRefresh} disabled={isLoading}
                        sx={{ backgroundColor: 'blue', color: 'white', '&:hover': { backgroundColor: 'darkblue' } }}>
                        {isLoading ? 'Aggiornamento...' : 'Aggiorna'}
                    </Button>
                </Box>
                <TableContainer component={Paper} sx={{ maxWidth: 1000, margin: 'auto' }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Servizio</TableCell>
                                <TableCell>Stato</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Object.entries(systemsHealth).map(([system, status]) => (
                                <TableRow key={system}>
                                    <TableCell>{system}</TableCell>
                                    <TableCell sx={{ color: status.startsWith('Inattivo') ? 'red' : status.startsWith('Manutenzione') ? 'orange' : 'green' }}>
                                        {status}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
               
            </ThemeProvider>
        </div>
    );
}

export default Page_Infrastructurestatus;
