import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography
} from '@mui/material';

const Modal_CardRechargesBlocked = ({ open, handleClose, recharges }) => {
  const safeRecharges = Array.isArray(recharges) ? recharges : [];

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>Ricariche carte bloccate</DialogTitle>
      <DialogContent>
        {safeRecharges.length > 0 ? (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID Ricarica</TableCell>
                  <TableCell>Data</TableCell>
                  <TableCell>Importo</TableCell>
                  <TableCell>Mittente</TableCell>
                  <TableCell>Destinatario</TableCell>
                  <TableCell>Stato precedente</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {safeRecharges.map((recharge) => (
                  <TableRow key={recharge.bank_transfer_id}>
                    <TableCell>{recharge.bank_transfer_id}</TableCell>
                    <TableCell>{new Date(recharge.transaction_date_time).toLocaleString('it-IT')}</TableCell>
                    <TableCell>
                      {new Intl.NumberFormat('it-IT', { style: 'currency', currency: recharge.currency }).format(recharge.amount)}
                    </TableCell>
                    <TableCell>{recharge.sender_name}</TableCell>
                    <TableCell>{recharge.account_to}</TableCell>
                    <TableCell>{recharge.change_from_status}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Typography>Nessuna ricarica carta bloccata da visualizzare.</Typography>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default Modal_CardRechargesBlocked;