import React, { useState, useEffect } from 'react';
import {  ThemeProvider } from '@mui/material';
import { theme } from './ReactStyles';

function Page_Kycstatus({ onDataChange, ...props }) {


  return (
    <div style={{ marginLeft: 'auto', marginRight: 'auto', maxWidth: '1500px' }}>
      <ThemeProvider theme={theme}>
        UNDER CONSTRUCTION
      </ThemeProvider>
    </div >
  );
}

export default Page_Kycstatus;