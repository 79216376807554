import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, TextField, CircularProgress, Typography } from '@mui/material';
import { update_user_email } from './Util_API_calls'; // Import the update email function

const Modal_UserDetails = ({ isOpen, onClose, user }) => {
  const [email, setEmail] = useState(user.email);
  const [isProcessing, setIsProcessing] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSave = async () => {
    setIsProcessing(true);
    setErrorMessage('');
    setSuccessMessage('');

    // Email validation
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(email)) {
      setErrorMessage('Errore, inserire l\'email corretta');
      setIsProcessing(false);
      return;
    }

    try {
      const result = await update_user_email(user.email, email);
      if (result.status === 'success') {
        setSuccessMessage(`This is the new email: ${email}`);
      } else {
        setErrorMessage(result.message);
      }
    } catch (error) {
      setErrorMessage(error.message);
    }

    setIsProcessing(false);
  };

  const handleClose = () => {
    setErrorMessage('');
    setSuccessMessage('');
    onClose();
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>Aggiorna email</DialogTitle>
      <DialogContent>
        {errorMessage && <Typography color="error">{errorMessage}</Typography>}
        {successMessage ? (
          <Typography variant="body1">{successMessage}</Typography>
        ) : (
          <>
          <Typography variant="body1" gutterBottom>
          First Name: {user.first_name}
          </Typography>
          <Typography variant="body1" gutterBottom>
          Last Name: {user.last_name}
          </Typography>
          <TextField
              label="Email"
              value={email}
              onChange={handleEmailChange}
              fullWidth
              margin="normal"
            />
           <Typography variant="body1" gutterBottom>
           Customer ID: {user.customer_id}
           </Typography>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} disabled={isProcessing}>
          {successMessage ? 'OK' : 'Cancel'}
        </Button>
        {!successMessage && (
          <Button onClick={handleSave} color="primary" variant="contained" disabled={isProcessing}>
            {isProcessing ? <CircularProgress size={24} color="inherit" /> : 'Save'}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default Modal_UserDetails;
