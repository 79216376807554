// src/FeesProva.js

import React, { useState, useEffect } from 'react';
import { theme } from './ReactStyles';
import { CircularProgress, Table, TableBody, TableCell, TableHead, TableRow, TablePagination, ThemeProvider, Button, Box, Typography } from '@mui/material';
import { get_fee_values } from './Util_API_calls';
import { number_to_italian_currency } from './Util_format';
import * as XLSX from 'xlsx';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

function FeesProva() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [feeData, setFeeData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const exportToExcel = () => {
    const ws = XLSX.utils.json_to_sheet(feeData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Fee Values");
    XLSX.writeFile(wb, "fee_values.xlsx");
  };

  useEffect(() => {
    const fetchFeeValues = async () => {
      setLoading(true);
      const response = await get_fee_values();
      console.log(response);
      if (response) {
        setFeeData(response);
      } else {
        setError('Failed to fetch data.');
      }
      setLoading(false);
    };

    fetchFeeValues();
  }, []);

  // Flatten the feeData for rendering
  const flattenedData = [];
  if (feeData && typeof feeData === 'object') {
    Object.keys(feeData).forEach(feeType => {
      const feeArray = feeData[feeType];
      if (Array.isArray(feeArray)) {
        feeArray.forEach(item => {
          flattenedData.push({ ...item, feeType });
        });
      }
    });
  }

  return (
    <div style={{ marginLeft: 'auto', marginRight: 'auto', marginTop: '30px' }}>
      <ThemeProvider theme={theme}>
        <Box>
          <Button
            color="primary"
            onClick={exportToExcel}
            style={{ margin: '10px' }}
          >
            <CloudDownloadIcon />
          </Button>
        </Box>
        {loading ? (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <CircularProgress />
          </div>
        ) : error ? (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <Typography variant="h6" color="error">
              {error}
            </Typography>
          </div>
        ) : (
          <>
            <Table style={{ marginTop: '20px' }}>
              <TableHead>
                <TableRow>
                  <TableCell>Fee Type</TableCell>
                  <TableCell>Label</TableCell>
                  <TableCell>Value</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {flattenedData
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((data, index) => (
                    <TableRow key={index}>
                      <TableCell>{data.feeType}</TableCell>
                      <TableCell>{data.fee_label}</TableCell>
                      <TableCell>{number_to_italian_currency(data.customer_fixed_component)}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            <TablePagination
              component="div"
              count={flattenedData.length}
              page={page}
              onPageChange={(event, newPage) => setPage(newPage)}
              rowsPerPage={rowsPerPage}
              labelRowsPerPage={'Rows per page'}
              onRowsPerPageChange={(event) => {
                setRowsPerPage(parseInt(event.target.value, 10));
                setPage(0);
              }}
            />
          </>
        )}
      </ThemeProvider>
    </div>
  );
}

export default FeesProva;
